import React, { useEffect, useState, useContext } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { ProjectStatusContext } from './Providers/ProjectStatusProvider';
import SSOLoader from './Components/SSOLoader';
function OtherSplitModel({ dnrDataSet, dummy, headings, noOfRows, fromYear }) {
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const [loader, setLoader] = useState(false)
    const [textareaValue, setTextareaValue] = useState({});
    const [tableData, setTableData] = useState({});
    const splitTypeOptions = headings;
    const [splitType, setSplitType] = useState('');
    const [level, setLevel] = useState(0);
    const [currentId, setCurrentId] = useState('');
    const [isTableView, setIsTableView] = useState({})
    const [splitSegments, setSplitSegments] = useState([]);
    const [dummyState, setDummyState] = useState(0);
    const [calculation, setCalculation] = useState(false)
    const [splitData, setSplitData] = useState({});
    const [valueTab, setValueTab] = useState(0);
    const [totalData, setTotalData] = useState({});
    const [tabHeadings, setTabHeadings] = useState([]);
    const [customHeadings, setCustomHeadings] = useState([])
    const project_id = window.location.href.split('/').pop();

    const getUserDetailsFromLocalStorage = () => {
        const userDetailsString = sessionStorage.getItem('userDetails');
        if (userDetailsString) {
            return JSON.parse(userDetailsString);
        } else {
            return null;
        }
    };
    const handleTableChanges = (key, e, pindex, chlidIndex) => {
        let tempTable = { ...tableData };
        tempTable[key][pindex][chlidIndex] = e.target.value;
        setTableData(tempTable)
    }

    function getParentWithChildren(id, data) {
        function findNodeById(nodeId, nodes) {
            for (const node of nodes) {
                if (node.Id === nodeId || node.id === nodeId) {
                    return node;
                }
                if (node.children) {
                    const found = findNodeById(nodeId, node.children);
                    if (found) {
                        return found;
                    }
                }
            }
            return null;
        }

        const node = findNodeById(id, data);
        if (!node) return null;

        const result = [
            {
                id: node.Id || node.id,
                name: node.name,
                level: node.level,
                children: node.children
            },
            ...node.children.map(child => ({
                id: child.id,
                name: child.name,
                level: child.level,
                children: child?.children
            }))
        ];

        return result;
    }

    function extractNodes(nodes) {
        const result = [];

        function traverse(node) {
            if (node.level === 0 || node.level === 1) {
                result.push({
                    id: node.id,
                    name: node.name,
                    level: node.level,
                    children: node.children
                });
            }

            if (node.level < 1) {
                node.children.forEach(child => traverse(child));
            }
        }

        nodes.forEach(node => traverse(node));

        return result;
    }

    useEffect(() => {
        if (dnrDataSet?.initialNodes) {
            const extractedNodes = extractNodes(dnrDataSet?.initialNodes);
            const newValue = extractedNodes.filter(e => e?.level === 0);
            setValueTab(newValue[0]?.id);
            setSplitSegments(extractedNodes);
            setTabHeadings(extractedNodes);
            setCurrentId(newValue[0]?.id);
            setLevel(newValue[0]?.level);
        }
    }, [dnrDataSet]);

    const transformData = (otherData) => {
        const firstYearData = otherData?.[0]?.[Object.keys(otherData?.[0] || {})?.[0]] || [];
        const headers = Object.keys(firstYearData);
        let headArr = headers.map((e) => ({ name: e, value: e }))

        const result = [];
        result.push(headers);

        otherData.forEach(obj => {
            const yearData = Object.values(obj)[0];
            const row = headers.map(header => yearData[header]);
            result.push(row.map(val => val));
        });
        return result;
    }
    const getSplitData = async (id, currentLevel) => {
        try {
            const seg = id || tabHeadings.filter(e => e?.level === 0)[0]?.id;
            const Level = currentLevel || 0;

            const params = {
                seg_id: seg,
                user_id: getUserDetailsFromLocalStorage()?.user_id,
                level: Level
            };

            const data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/getsplitmodel/`, { params });
            setSplitType(data?.data?.formula_type)
            let TableView = Object.keys(data?.data?.split_data)?.map((ele) => ({ [ele]: (data?.data?.split_data?.[ele]).length ? true : false }))
            let singleObject = TableView.reduce((acc, current) => {
                return { ...acc, ...current };
            }, {});

            setIsTableView(singleObject)
            let tabledata = {};
            Object.keys(singleObject)?.map((key) => {
                if (key) {
                    tabledata[key] = transformData(data?.data?.split_data[key])
                }
            })
            setTableData(tabledata)
            setTotalData(data?.data?.total_data);
            let dataArray = data?.data?.total_data
            // console.log(Object.values(Object.values(dataArray)[0]))
            setCustomHeadings(Object.keys(dataArray[0][Object.keys(dataArray[0])]))
            if (Object.keys(data?.data?.total_data).length == 0) {
                setCalculation(false)
            }

        } catch (error) {
            console.error(error);
        }
    };

    const fillExtraArray = (arr, len) => {
        const currentLength = arr.length;
        if (currentLength <= noOfRows + 1) {
            for (let i = currentLength; i < noOfRows + 1; i++) {
                arr.push(new Array(len).fill(""));
            }
        }
        for (let i = 0; i < arr.length; i++) {
            while (arr[i].length < len) {
                arr[i].push('');
            }
        }
        return arr;
    };

    const generateTable = (key) => {
        if(Object.keys(textareaValue).length===0){
            toast.error('Please fill values')
            return;
        }
        if (textareaValue[key].length <= 0) {
            toast.error('Please fill values')
            return;
        }
        if (textareaValue[key].split("\n").length > noOfRows + 1) {
            toast.error(`maximum ${noOfRows + 1}  rows`)
            return;
        }
        let tempArr = textareaValue[key].split("\n").map((row, index) => row.split("\t"))
        setTableData({ ...tableData, [key]: fillExtraArray(tempArr, findMaxLength(tempArr)) })
        setIsTableView({ ...isTableView, [key]: true })
    }

    const findMaxLength = (arrayOfArrays) => {
        let maxLength = 0;

        arrayOfArrays.forEach(subArray => {
            if (subArray.length > maxLength) {
                maxLength = subArray.length;
            }
        });

        return maxLength;
    };
    const generateString = (key) => {
        setTextareaValue({ ...textareaValue, [key]: tableData[key].map(row => row.join("\t")).join("\n") })
        setIsTableView({ ...isTableView, [key]: false })
    }

    const handleChange = async (newValue) => {

        setSplitSegments(getParentWithChildren(newValue, tabHeadings));
        setValueTab(newValue);
        const level = tabHeadings.filter(e => e?.id === newValue)[0].level;
        setLevel(level);
        setCurrentId(newValue);
        setSplitData(null);
        setSplitType('')


    };

    function calculateRevenue(prevrevenue, curgrowth) {
        let data = prevrevenue * (100 + parseFloat(curgrowth)) / 100
        return data.toFixed(2)
    }

    function calculaterevenuebymarket(curmarket, currtotrevenue) {
        let data = curmarket * currtotrevenue / 100
        return data.toFixed(2)
    }

    function calulateGrowth(currrevenue, prevrevenue) {
        let data = (currrevenue - prevrevenue) / prevrevenue * 100
        return data.toFixed(2)
    }

    function marketValue(currevenue, currtotrevenue) {
        let data = (currevenue / currtotrevenue) * 100
        return data.toFixed(2)
    }

    const handleInputChange = (segmentId, year, field, value) => {
        setSplitData(prevSplitData => {
            const newSplitData = { ...prevSplitData };
            if (!newSplitData[segmentId]) {
                newSplitData[segmentId] = {};
            }
            if (!newSplitData[segmentId][year]) {
                newSplitData[segmentId][year] = {};
            }
            newSplitData[segmentId][year][field] = value;
            return newSplitData;
        });
    };

    useEffect(() => {
        if (currentId?.length > 0) {
            getSplitData(currentId, level);
        }
    }, [currentId, level, dummy]);

    const handleSplitTypeChange = (event) => {
        setSplitType(event.target.value);
    };


    const saveTableData = async (key) => {
        if (splitType?.length > 0) {
            try {
                const years = Array.from({ length: noOfRows }, (_, i) => fromYear + i);
                const fields = tableData[key][0];
                const result = [];

                for (let i = 0; i < years.length; i++) {
                    const yearData = {};
                    for (let j = 0; j < fields.length; j++) {
                        let value = parseFloat(tableData[key][i + 1][j]);
                        if (!isNaN(value)) {
                            yearData[fields[j]] = value;
                        } else {
                            yearData[fields[j]] = "";
                        }

                    }
                    result.push({ [years[i]]: yearData });
                }
                let postData = {
                    user_id: getUserDetailsFromLocalStorage()?.user_id,
                    seg_id: key,
                    dr_type: 'other',
                    other_data: result,
                    fields: fields,
                    formula_type: splitType
                }
                let response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/saveOthermodel/`, postData)
                setLoader(false)
                if (response?.status == 200) {
                    toast.success('External Model data saved')
                } else {
                    toast.error('Something went wrong')
                }
            } catch (error) {
                console.log(error);
                setLoader(false)
            }
        } else {
            toast.error('Please select formula type')
        }
    }
    return (
        <>
            {loader && <SSOLoader />}
            <div class="gp-external-models-section gp-split-model">
                <div class="gp-header">
                    <h2 class="mb-0">Split Model</h2>
                </div>
                <div class="body">
                    <div class="gp-formula-type">
                        {splitTypeOptions?.map((e) =>
                            <div className='gp-radio'>
                                <input type='radio' checked={splitType == e?.value} value={e?.value} onChange={handleSplitTypeChange} name='splitType' id={e} />
                                <label htmlFor={e}>{e?.name}</label>
                            </div>
                        )}
                    </div>
                    <ul class="gp-formula-selector">
                        {tabHeadings?.map(e => <li onClick={() => { handleChange(e?.id) }} className={valueTab == e?.id && 'active'} key={e?.id} value={e?.id} >{e?.name} </li>)}
                    </ul>
                    <div class="gp-table-wrapper">
                        {Object.keys(totalData).length > 0 ? (
                            splitSegments.map((e, index) => {
                                if (e?.id === valueTab) {
                                    return (
                                        <>
                                            <div className='row mt-2'>
                                                <div className='col-4 '>
                                                    <div className='table-responsive'>
                                                        <table className='table table-bordered table-striped table-sm text-center table-excelToHtml'>
                                                            <thead>
                                                                <tr>
                                                                    <td colSpan={customHeadings?.length + 1}>
                                                                        <h6 className='mb-0 font14'>{e?.level === level ? e?.name : 'Total'} </h6>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th className='align-middle' scope='col'>Year</th>
                                                                    {customHeadings.map((e) => <th className='align-middle'>{e}</th>)}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Object.keys(Object.values(totalData)[0] || {}).map((year, i) => (<tr key={i}>
                                                                    <td>{year}</td>
                                                                    {customHeadings.map((ele, index) => <td key={index}>{e?.[Object.keys(e)]?.[customHeadings?.[index]]}</td>)}
                                                                </tr>))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className='col-8'>
                                                    <div style={{ overflowX: 'auto',display:'flex' }}>
                                                       
                                                        {splitSegments.filter(segment => segment?.level !== level).map((segment, index) => (
                                                                <div className=' pe-2'>
                                                                    
                                                                        <div className='border p-2'>
                                                                            <h6 className='text-center mb-0 font14'>{segment?.name} <span className='float-end' style={{ color: 'red' }}>*Heading names should not repeat</span></h6>
                                                                        </div>
                                                                        {
                                                                            !isTableView?.[segment.id] && (
                                                                                <div className='table-responsive'>
                                                                            <table className='table table-sm table-bordered table-striped text-center otherTable-excelToHtml'>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className='align-middle'><span>Heading</span></td>
                                                                                        <td rowSpan={noOfRows + 1} colSpan={2} >
                                                                                            <textarea className='form-control form-control-sm ' style={{ minWidth: "500px" }} value={textareaValue[segment?.id]}
                                                                                                onChange={(e) => setTextareaValue({ ...textareaValue, [segment?.id]: e.target.value })} rows={noOfRows + 4} >
                                                                                            </textarea>
                                                                                        </td>
                                                                                    </tr>
                                                                                    {Array.from({ length: noOfRows }, (_, i) => fromYear + i).map((e) => <tr>
                                                                                        <td className='align-middle'><span>{e}</span></td>
                                                                                    </tr>)}

                                                                                </tbody>
                                                                            </table>
                                                                            </div>)
                                                                        }

                                                                        {
                                                                            (isTableView?.[segment?.id] && (tableData?.[segment?.id] || []).length) && (
                                                                                <div className='table-responsive'>
                                                                                    <table className='table table-sm table-bordered table-striped text-center otherTable-excelToHtml'>
                                                                                        <thead>
                                                                                            {tableData[segment?.id]?.map((ele, index) => {
                                                                                                if (index == 0) {
                                                                                                    return (
                                                                                                        <tr>
                                                                                                            <th className='align-middle' style={{ minWidth: "80px" }}>Years</th>
                                                                                                            {ele.map((data) => (
                                                                                                                <th>{data}</th>
                                                                                                            ))}
                                                                                                        </tr>
                                                                                                    )
                                                                                                }
                                                                                            })}
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {tableData[segment?.id]?.map((ele, index) => {
                                                                                                if (index !== 0) {
                                                                                                    return (
                                                                                                        <tr>
                                                                                                            <td className='align-middle' style={{ minWidth: "80px" }}>{Array.from({ length: noOfRows }, (_, i) => fromYear + i)?.[index - 1]}</td>
                                                                                                            {ele.map((data, index2) => (
                                                                                                                <td><input type='text' onInput={(e) => { handleTableChanges(segment?.id, e, index, index2) }} className='form-control form-control-sm other-models-table' value={data} /></td>
                                                                                                            ))}
                                                                                                        </tr>
                                                                                                    )
                                                                                                }
                                                                                            })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        <div className='text-center my-3'>
                                                                            {
                                                                                !isTableView?.[segment?.id] ?


                                                                                    <div class="gp-bottom-btn-part">
                                                                                        <button type="button" onClick={() => { generateTable(segment?.id) }} class="gp-generate-btn">GENERATE</button>
                                                                                    </div>
                                                                                    : <div className='d-flex justify-content-center'>
                                                                                        <div class="gp-bottom-btn-part">
                                                                                            <button type="button" onClick={() => { generateString(segment?.id) }} class="gp-generate-btn">EDIT MODE</button>
                                                                                        </div>
                                                                                        <div class="gp-bottom-btn-part">
                                                                                            <button type="button" onClick={() => { saveTableData(segment?.id) }} class="gp-generate-btn">Save</button>
                                                                                        </div>
                                                                                    </div>}
                                                                        </div>
                                                                    
                                                                </div>

                                                            ))}
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                    );
                                } else {
                                    console.log(valueTab, splitSegments, e?.id);
                                    return null; // Always return JSX or null in the map
                                }
                            })
                        ) : (
                            <div className='text-center'>
                                <span style={{ color: 'red' }}>
                                    *Please fill split details of the parent and then proceed to this tab
                                </span>
                            </div>
                        )}

                    </div>

                </div>
            </div>
        </>
    );
}

export default OtherSplitModel;
