import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { ProjectStatusContext } from '../../../Providers/ProjectStatusProvider';
import axios from 'axios';
import SSOLoader from '../../SSOLoader';
import wrong from '../../../images/no.png'
import { useGetDNRDataQuery } from '../../../DriversAndRestraints/Store/dataSlices/DriverAndRestraintAPISlice';
function OthersEditableDandR(props) {
    const getUserDetailsFromLocalStorage = () => {
        const userDetailsString = sessionStorage.getItem('userDetails')
        if (userDetailsString) {
            return JSON.parse(userDetailsString)
        } else {
            return null
        }
    }
    const { data: DnrData, error, isError, isLoading, isSuccess, } = useGetDNRDataQuery({ projectId: props?.toc?.go_in_id, userId: getUserDetailsFromLocalStorage().user_id });
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const [currentPair, setCurrentPair] = useState(['units', 'price', 'revenue'])
    const [col, setCol] = useState([])
    const [page, setPage] = useState("")
    const [loader, setLoader] = useState(false)
    const [driverRestraintNames, setDriverRestraintNames] = useState({
        unitsDrivers: {},
        unitsRestraints: {},
        priceDrivers: {},
        priceRestraints: {},
        revenueDrivers: {},
        revenueRestraints: {},
    })
    const [driverRestraintValues, setDriverRestraintValues] = useState({
        unitsDrivers: {},
        unitsRestraints: {},
        priceDrivers: {},
        priceRestraints: {},
        revenueDrivers: {},
        revenueRestraints: {},
    })

    const [count, setCount] = useState({
        unitsDrivers: '4',
        unitsRestraints: '4',
        priceDrivers: '4',
        priceRestraints: '4',
        revenueDrivers: '4',
        revenueRestraints: '4',
    })

    const deleteAndRekeyName = (obj, property, index) => {
        const subObj = obj[property]
        const entries = Object.entries(subObj)
        entries.splice(index, 1)
        const newSubObj = entries.reduce((acc, [key, value], i) => {
            acc[i] = value
            return acc
        }, {})
        obj[property] = newSubObj
        return obj
    }

    const deleteRow = (value, index) => {
        if (parseInt(count[value]) <= 4) {
            toast.error('Minimum 4 drivers/restraints required')
        } else {
            setCount((prevState) => ({
                ...prevState,
                [value]: parseInt(prevState[value]) - 1,
            }))
            setDriverRestraintValues(
                deleteAndRekeyName({ ...driverRestraintValues }, value, index)
            )
            setDriverRestraintNames(
                deleteAndRekeyName({ ...driverRestraintNames }, value, index)
            )
        }
    }
    const handleDriverRestraintNameChange = (type, index, value) => {
        setDriverRestraintNames((prevNames) => {
            const updatedNames = { ...prevNames }
            if (!updatedNames[type][index]) {
                updatedNames[type][index] = ''
            }
            updatedNames[type][index] = value
            return updatedNames
        })
    }

    function addNextDriverName(obj, key) {
        const subObj = obj[key]
        const keys = Object.keys(subObj).map(Number)
        const maxKey = Math.max(...keys)
        const lastElementName = subObj[maxKey]
        const newElementName = lastElementName.replace(
            /\d+$/,
            (match) => Number(match) + 1
        )
        subObj[maxKey + 1] = newElementName
        return obj
    }
    const addRow = (value) => {
        const keys = Object.keys(driverRestraintValues[value])
        let lastObject = { ...driverRestraintValues[value][keys[keys.length - 1]] }
        Object.keys(lastObject)?.map((ele) => (lastObject[ele] = 'No Impact'))
        let updatedValues = { ...driverRestraintValues[value] }
        updatedValues[keys.length] = lastObject
        setDriverRestraintNames(
            addNextDriverName({ ...driverRestraintNames }, value)
        )
        setDriverRestraintValues((prev) => ({
            ...prev,
            [value]: updatedValues,
        }))
        setCount((prevState) => ({
            ...prevState,
            [value]: parseInt(prevState[value]) + 1,
        }))
    }

    function deepClone(obj) {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            let arrCopy = [];
            obj.forEach((_, i) => {
                arrCopy[i] = deepClone(obj[i]);
            });
            return arrCopy;
        }

        let objCopy = {};
        Object.keys(obj).forEach((key) => {
            objCopy[key] = deepClone(obj[key]);
        });
        return objCopy;
    }

    const handleDriverRestraintChange = (type, index, year, value) => {
        setDriverRestraintValues((prevValues) => {
            var updatedValues = deepClone(prevValues)
            if (!updatedValues[type][index]) {
                updatedValues[type][index] = {}
            }
            updatedValues[type][index][year] = value
            return updatedValues
        })
    }


    const generateInitialNameState = () => {
        const initialState = {
            unitsDrivers: {},
            unitsRestraints: {},
            priceDrivers: {},
            priceRestraints: {},
            revenueDrivers: {},
            revenueRestraints: {},
        }

        currentPair?.forEach((pair) => {
            for (let i = 0; i < count[`${pair}Drivers`]; i++) {
                initialState[`${pair}Drivers`][i] = `${pair}Driver ${i + 1}`
            }
            for (let i = 0; i < count[`${pair}Restraints`]; i++) {
                initialState[`${pair}Restraints`][i] = `${pair}Restraint ${i + 1}`
            }
        })

        return initialState
    }
    const generateInitialValueState = () => {
        const initialState = {
            unitsDrivers: {},
            unitsRestraints: {},
            priceDrivers: {},
            priceRestraints: {},
            revenueDrivers: {},
            revenueRestraints: {},
        }


        currentPair.forEach((pair) => {
            for (let i = 0; i < count[`${pair}Drivers`]; i++) {
                const driverKey = `${pair}Drivers`
                initialState[driverKey][i] = {}
                col.forEach((year) => {
                    initialState[driverKey][i][year] = 'No Impact'
                })
            }
            for (let i = 0; i < count[`${pair}Restraints`]; i++) {
                const restraintKey = `${pair}Restraints`

                initialState[restraintKey][i] = {}

                col.forEach((year) => {
                    initialState[restraintKey][i][year] = 'No Impact'
                })
            }
        })

        return initialState
    }
    const getProjectDetails = async () => {
        let project_id = '864eb7c0-3c4d-4543-a80c-447f8fe6ad2f'
        const url = window.location.href
        const segments = url.split('/').pop()
        const id = segments
        try {
            let data = await axios.get(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${id}`
            )
            generateYears(
                parseInt(data?.data?.data?.baseyear),
                parseInt(data?.data?.data?.study_from),
                parseInt(data?.data?.data?.study_to)
            )
        } catch (error) {
            console.log(error)
        }
    }

    function generateYears(baseYear, studyFrom, studyTo) {
        const forecastYears = []
        const historicYears = []

        for (let year = studyFrom; year <= studyTo; year++) {
            if (year > baseYear) {
                forecastYears.push(year)
            } else if (year <= baseYear) {
                historicYears.push(year)
            }
        }
        setCol(forecastYears)
    }

    const stepTwo = async () => {
        setLoader(true)
        let dataArr = replaceIndicesWithNames(
            driverRestraintValues,
            driverRestraintNames
        )
        const url = window.location.href
        const segments = url.split('/').pop()
        const id = segments
        let postData = {
            user_id: getUserDetailsFromLocalStorage()?.user_id,
            seg_id: DnrData?.initialNodes[0]?.id,
            drivers_restrainsts_data: dataArr,
        }
        try {
            let response = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${id}/saveDriversRestraints`,
                postData
            )
            props?.refetch();
            if (response?.status == 200) {
                toast.success('Drivers and Restraints Saved')
            } else {
                toast.error('Something went wrong')
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log(error)
            toast.error('Something went wrong')
        }
    }

    function replaceIndicesWithNames(obj1, obj2) {
        const result = {}

        for (const key in obj1) {
            if (obj1.hasOwnProperty(key)) {
                result[key] = {}
                for (const subKey in obj1[key]) {
                    if (obj1[key].hasOwnProperty(subKey) && obj2[key]) {
                        const newSubKey = obj2[key][subKey]
                        result[key][newSubKey] = obj1[key][subKey]
                    } else {
                        result[key][subKey] = obj1[key][subKey]
                    }
                }
            }
        }

        return result
    }
    const transformDNRData = (data) => {
        const transformedData = {}

        const transformCategory = (category) => {
            const newCategory = {}
            const keys = Object.keys(category)
            keys.forEach((key, index) => {
                newCategory[index] = category[key]
            })
            return newCategory
        }

        transformedData['unitsDrivers'] = transformCategory(data.unitDrivers)
        transformedData['unitsRestraints'] = transformCategory(data.unitsRestraints)
        transformedData['priceDrivers'] = transformCategory(data.priceDrivers)
        transformedData['priceRestraints'] = transformCategory(data.priceRestraints)
        transformedData['revenueDrivers'] = transformCategory(data.revenueDrivers)
        transformedData['revenueRestraints'] = transformCategory(data.revenueRestraints)

        return transformedData
    }
    const transformNameData = (data) => {
        const transformedData = {}

        const transformCategory = (category) => {
            const newCategory = {}
            const keys = Object.keys(category)
            keys.forEach((key, index) => {
                newCategory[index] = key
            })
            return newCategory
        }

        transformedData['unitsDrivers'] = transformCategory(data.unitDrivers)
        transformedData['unitsRestraints'] = transformCategory(data.unitsRestraints)
        transformedData['priceDrivers'] = transformCategory(data.priceDrivers)
        transformedData['priceRestraints'] = transformCategory(data.priceRestraints)
        transformedData['revenueDrivers'] = transformCategory(data.revenueDrivers)
        transformedData['revenueRestraints'] = transformCategory(data.revenueRestraints)

        return transformedData
    }

    const isEmpty = (obj) => {
        for (const prop in obj) {
            if (Object.hasOwn(obj, prop)) {
                return false
            }
        }

        return true
    }
    useEffect(() => {
        setPage(props?.type || "")
        if (!isEmpty(props?.allData?.data?.basic_input_data)) {
            setCount({
                unitsDrivers:
                    parseInt(props?.allData?.data?.basic_input_data?.no_of_unit_drivers) >=
                        3
                        ? props?.allData?.data?.basic_input_data?.no_of_unit_drivers
                        : 4,
                unitsRestraints:
                    parseInt(
                        props?.allData?.data?.basic_input_data?.no_of_unit_restraints
                    ) >= 3
                        ? props?.allData?.data?.basic_input_data?.no_of_unit_restraints
                        : 4,
                priceDrivers:
                    parseInt(props?.allData?.data?.basic_input_data?.no_of_price_drivers) >=
                        3
                        ? props?.allData?.data?.basic_input_data?.no_of_price_drivers
                        : 4,
                priceRestraints:
                    parseInt(
                        props?.allData?.data?.basic_input_data?.no_of_price_restraints
                    ) >= 3
                        ? props?.allData?.data?.basic_input_data?.no_of_price_restraints
                        : 4,
                revenueDrivers:
                    parseInt(
                        props?.allData?.data?.basic_input_data?.no_of_revenue_drivers
                    ) >= 3
                        ? props?.allData?.data?.basic_input_data?.no_of_revenue_drivers
                        : 4,
                revenueRestraints:
                    parseInt(
                        props?.allData?.data?.basic_input_data?.no_of_revenue_restraints
                    ) >= 3
                        ? props?.allData?.data?.basic_input_data?.no_of_revenue_restraints
                        : 4,
            })
        }
        if (!isEmpty(props?.allData?.drivers_restrainsts_data)) {
            let nameData = transformNameData(props?.allData?.drivers_restrainsts_data)
            setDriverRestraintNames(nameData)
            let dnrData = transformDNRData(props?.allData?.drivers_restrainsts_data)
            setDriverRestraintValues(dnrData)
        } else {
            setDriverRestraintNames(generateInitialNameState())
            setDriverRestraintValues(generateInitialValueState())
        }
    }, [props])

    useEffect(() => {
        getProjectDetails()
    }, [])

    return (
        <>
            <div style={{ paddingLeft: '92%', paddingTop: '10%' }}>
                <p className=' d-flex justify-content-end ' style={{ position: 'fixed', color: 'red', backgroundColor: 'yellow' }}>Auto&nbsp;save <img className='ms-2' style={{ height: '15px' }} src={wrong} /></p>
            </div>
            <div className='card-body'>
                {loader ? <SSOLoader /> : null}
                {
                    page == "unitDrivers" &&
                    <div className='card-body'>
                        <table className='table table-bordered table-striped text-center table-excelToHtml'>
                            <thead className='thead-'>
                                <tr>
                                    <th>Rank</th>
                                    <th>Drivers</th>
                                    {col.map((year) => (
                                        <th key={year}>{year}</th>
                                    ))}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from(
                                    { length: count[currentPair[0] + 'Drivers'] },
                                    (v, i) => (
                                        <tr key={i}>
                                            <td className='align-middle'>{i + 1}</td>
                                            <td>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-sm'
                                                    value={
                                                        driverRestraintNames[currentPair[0] + 'Drivers'][
                                                        i
                                                        ]
                                                    }
                                                    onChange={(e) =>
                                                        handleDriverRestraintNameChange(
                                                            currentPair[0] + 'Drivers',
                                                            i,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </td>
                                            {col.map((year) => (
                                                <td key={year}>
                                                    <select
                                                        className='form-select '
                                                        value={
                                                            driverRestraintValues[
                                                            currentPair[0] + 'Drivers'
                                                            ][i]?.[year] || 'No Impact'
                                                        }
                                                        onChange={(e) =>
                                                            handleDriverRestraintChange(
                                                                currentPair[0] + 'Drivers',
                                                                i,
                                                                year,
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <option>No Impact</option>
                                                        <option>Very Low</option>
                                                        <option>Low</option>
                                                        <option>Low/Medium</option>
                                                        <option>Medium</option>
                                                        <option>Medium/High</option>
                                                        <option>High</option>
                                                        <option>Very High</option>
                                                    </select>
                                                </td>
                                            ))}
                                            {isProjectEditable && <td className='align-middle'>
                                                <i
                                                    onClick={() =>
                                                        deleteRow(currentPair[0] + 'Drivers', i)
                                                    }
                                                    style={{ color: 'red' }}
                                                    className='fa fa-trash font16'
                                                ></i>
                                            </td>}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                        {isProjectEditable && <div className='d-flex justify-content-center'>
                            <button
                                type='button'
                                className='btn btn-outline-secondary btn-sm mb-3 rounded-0'
                                onClick={() => {
                                    addRow(currentPair[0] + 'Drivers')
                                }}
                            >
                                <i className='fa fa-plus me-1'></i>
                                Add Line
                            </button>
                        </div>}
                        {isProjectEditable && <div className='d-flex justify-content-end align-items-center'>
                            <span style={{ color: 'red' }} className='mt-3 me-2 font14'>
                                *Save each section to prevent data loss
                            </span>
                            <div class="gp-action-btn-part me-2 mt-3">
                                <button type="button" class="gp-btn-fill" onClick={stepTwo} >Save</button>
                            </div>
                        </div>}
                    </div>
                }

                {
                    page == "unitRestraints" && (
                        <div className='card-body'>
                            <table className='table table-bordered table-striped text-center table-excelToHtml'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Rank</th>
                                        <th>Restraints</th>
                                        {col.map((year) => (
                                            <th key={year}>{year}</th>
                                        ))}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from(
                                        { length: count[currentPair[0] + 'Restraints'] },
                                        (v, i) => (
                                            <tr key={i}>
                                                <td className='align-middle'>{i + 1}</td>
                                                <td>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-sm'
                                                        value={
                                                            driverRestraintNames[
                                                            currentPair[0] + 'Restraints'
                                                            ][i]
                                                        }
                                                        onChange={(e) =>
                                                            handleDriverRestraintNameChange(
                                                                currentPair[0] + 'Restraints',
                                                                i,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </td>

                                                {col.map((year) => (
                                                    <td key={year}>
                                                        <select
                                                            className='form-select '
                                                            value={
                                                                driverRestraintValues[
                                                                currentPair[0] + 'Restraints'
                                                                ][i]?.[year] || 'No Impact'
                                                            }
                                                            onChange={(e) =>
                                                                handleDriverRestraintChange(
                                                                    currentPair[0] + 'Restraints',
                                                                    i,
                                                                    year,
                                                                    e.target.value
                                                                )
                                                            }
                                                        >
                                                            <option>No Impact</option>
                                                            <option>Very Low</option>
                                                            <option>Low</option>
                                                            <option>Low/Medium</option>
                                                            <option>Medium</option>
                                                            <option>Medium/High</option>
                                                            <option>High</option>
                                                            <option>Very High</option>
                                                        </select>
                                                    </td>
                                                ))}
                                                {isProjectEditable && <td className='align-middle'>
                                                    <i
                                                        onClick={() =>
                                                            deleteRow(currentPair[0] + 'Restraints', i)
                                                        }
                                                        style={{ color: 'red' }}
                                                        className='fa fa-trash font16'
                                                    ></i>
                                                </td>}
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                            {isProjectEditable && <div className='d-flex justify-content-center'>
                                <button
                                    type='button'
                                    className='btn btn-outline-secondary btn-sm mb-3 rounded-0'
                                    onClick={() => {
                                        addRow(currentPair[0] + 'Restraints')
                                    }}
                                >
                                    <i className='fa fa-plus me-1'></i>
                                    Add Line
                                </button>
                            </div>}
                            {isProjectEditable && <div className='d-flex justify-content-end align-items-center'>
                                <span style={{ color: 'red' }} className='mt-3 me-2 font14'>
                                    *Save each section to prevent data loss
                                </span>
                                <div class="gp-action-btn-part me-2 mt-3">
                                    <button type="button" class="gp-btn-fill" onClick={stepTwo} >Save</button>
                                </div>
                            </div>}
                        </div>
                    )
                }

                {page == "priceDrivers" && (
                    <div className='card-body'>
                        <table className='table table-bordered table-striped text-center table-excelToHtml'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Rank</th>
                                    <th>Drivers</th>
                                    {col.map((year) => (
                                        <th key={year}>{year}</th>
                                    ))}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from(
                                    { length: count[currentPair[1] + 'Drivers'] },
                                    (v, i) => (
                                        <tr key={i}>
                                            <td className='align-middle'>{i + 1}</td>
                                            <td>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-sm'
                                                    value={
                                                        driverRestraintNames[currentPair[1] + 'Drivers'][
                                                        i
                                                        ]
                                                    }
                                                    onChange={(e) =>
                                                        handleDriverRestraintNameChange(
                                                            currentPair[1] + 'Drivers',
                                                            i,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </td>
                                            {col.map((year) => (
                                                <td key={year}>
                                                    <select
                                                        className='form-select '
                                                        value={
                                                            driverRestraintValues[
                                                            currentPair[1] + 'Drivers'
                                                            ][i]?.[year] || 'No Impact'
                                                        }
                                                        onChange={(e) =>
                                                            handleDriverRestraintChange(
                                                                currentPair[1] + 'Drivers',
                                                                i,
                                                                year,
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <option>No Impact</option>
                                                        <option>Very Low</option>
                                                        <option>Low</option>
                                                        <option>Low/Medium</option>
                                                        <option>Medium</option>
                                                        <option>Medium/High</option>
                                                        <option>High</option>
                                                        <option>Very High</option>
                                                    </select>
                                                </td>
                                            ))}
                                            {isProjectEditable && <td className='align-middle'>
                                                <i
                                                    onClick={() =>
                                                        deleteRow(currentPair[1] + 'Drivers', i)
                                                    }
                                                    style={{ color: 'red' }}
                                                    className='fa fa-trash font16'
                                                ></i>
                                            </td>}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                        {isProjectEditable && <div className='d-flex justify-content-center'>
                            <button
                                type='button'
                                className='btn btn-outline-secondary btn-sm mb-3 rounded-0'
                                onClick={() => {
                                    addRow(currentPair[1] + 'Drivers')
                                }}
                            >
                                <i className='fa fa-plus me-1'></i>
                                Add Line
                            </button>
                        </div>}
                        {isProjectEditable && <div className='d-flex justify-content-end align-items-center'>
                            <span style={{ color: 'red' }} className='mt-3 me-2 font14'>
                                *Save each section to prevent data loss
                            </span>
                            <div class="gp-action-btn-part me-2 mt-3">
                                <button type="button" class="gp-btn-fill" onClick={stepTwo} >Save</button>
                            </div>
                        </div>}
                    </div>
                )}

                {page == "priceRestraints" && (
                    <div className='card-body'>
                        <table className='table table-bordered table-striped text-center table-excelToHtml'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Rank</th>
                                    <th>Restraints</th>
                                    {col.map((year) => (
                                        <th key={year}>{year}</th>
                                    ))}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from(
                                    { length: count[currentPair[1] + 'Restraints'] },
                                    (v, i) => (
                                        <tr key={i}>
                                            <td className='align-middle'>{i + 1}</td>
                                            <td>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-sm'
                                                    value={
                                                        driverRestraintNames[
                                                        currentPair[1] + 'Restraints'
                                                        ][i]
                                                    }
                                                    onChange={(e) =>
                                                        handleDriverRestraintNameChange(
                                                            currentPair[1] + 'Restraints',
                                                            i,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </td>
                                            {col.map((year) => (
                                                <td key={year}>
                                                    <select
                                                        className='form-select '
                                                        value={
                                                            driverRestraintValues[
                                                            currentPair[1] + 'Restraints'
                                                            ][i]?.[year] || 'No Impact'
                                                        }
                                                        onChange={(e) =>
                                                            handleDriverRestraintChange(
                                                                currentPair[1] + 'Restraints',
                                                                i,
                                                                year,
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <option>No Impact</option>
                                                        <option>Very Low</option>
                                                        <option>Low</option>
                                                        <option>Low/Medium</option>
                                                        <option>Medium</option>
                                                        <option>Medium/High</option>
                                                        <option>High</option>
                                                        <option>Very High</option>
                                                    </select>
                                                </td>
                                            ))}
                                            {isProjectEditable && <td className='align-middle'>
                                                <i
                                                    onClick={() =>
                                                        deleteRow(currentPair[1] + 'Restraints', i)
                                                    }
                                                    style={{ color: 'red' }}
                                                    className='fa fa-trash font16'
                                                ></i>
                                            </td>}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                        {isProjectEditable && <div className='d-flex justify-content-center'>
                            <button
                                type='button'
                                className='btn btn-outline-secondary btn-sm mb-3 rounded-0'
                                onClick={() => {
                                    addRow(currentPair[1] + 'Restraints')
                                }}
                            >
                                <i className='fa fa-plus me-1'></i>
                                Add Line
                            </button>
                        </div>}
                        {isProjectEditable && <div className='d-flex justify-content-end align-items-center'>
                            <span style={{ color: 'red' }} className='mt-3 me-2 font14'>
                                *Save each section to prevent data loss
                            </span>
                            <div class="gp-action-btn-part me-2 mt-3">
                                <button type="button" class="gp-btn-fill" onClick={stepTwo} >Save</button>
                            </div>
                        </div>}
                    </div>
                )}

                {page == "revenueDrivers" && (
                    <div className='card-body'>
                        <table className='table table-bordered table-striped text-center table-excelToHtml'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Rank</th>
                                    <th>Driver</th>
                                    {col.map((year) => (
                                        <th key={year}>{year}</th>
                                    ))}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from(
                                    { length: count[currentPair[2] + 'Drivers'] },
                                    (v, i) => (
                                        <tr key={i}>
                                            <td className='align-middle'>{i + 1}</td>
                                            <td>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-sm'
                                                    value={
                                                        driverRestraintNames[
                                                        currentPair[2] + 'Drivers'
                                                        ][i]
                                                    }
                                                    onChange={(e) =>
                                                        handleDriverRestraintNameChange(
                                                            currentPair[2] + 'Drivers',
                                                            i,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </td>
                                            {col.map((year) => (
                                                <td key={year}>
                                                    <select
                                                        className='form-select '
                                                        value={
                                                            driverRestraintValues[
                                                            currentPair[2] + 'Drivers'
                                                            ][i]?.[year] || 'No Impact'
                                                        }
                                                        onChange={(e) =>
                                                            handleDriverRestraintChange(
                                                                currentPair[2] + 'Drivers',
                                                                i,
                                                                year,
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <option>No Impact</option>
                                                        <option>Very Low</option>
                                                        <option>Low</option>
                                                        <option>Low/Medium</option>
                                                        <option>Medium</option>
                                                        <option>Medium/High</option>
                                                        <option>High</option>
                                                        <option>Very High</option>
                                                    </select>
                                                </td>
                                            ))}
                                            {isProjectEditable && <td className='align-middle'>
                                                <i
                                                    onClick={() =>
                                                        deleteRow(currentPair[2] + 'Drivers', i)
                                                    }
                                                    style={{ color: 'red' }}
                                                    className='fa fa-trash font16'
                                                ></i>
                                            </td>}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                        {isProjectEditable && <div className='d-flex justify-content-center'>
                            <button
                                type='button'
                                className='btn btn-outline-secondary btn-sm mb-3 rounded-0'
                                onClick={() => {
                                    addRow(currentPair[2] + 'Drivers')
                                }}
                            >
                                <i className='fa fa-plus me-1'></i>
                                Add Line
                            </button>
                        </div>}
                        {isProjectEditable && <div className='d-flex justify-content-end align-items-center'>
                            <span style={{ color: 'red' }} className='mt-3 me-2 font14'>
                                *Save each section to prevent data loss
                            </span>
                            <div class="gp-action-btn-part me-2 mt-3">
                                <button type="button" class="gp-btn-fill" onClick={stepTwo} >Save</button>
                            </div>
                        </div>}
                    </div>
                )}

                {page == "revenueRestraints" && (
                    <div className='card-body'>
                        <table className='table table-bordered table-striped text-center table-excelToHtml'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Rank</th>
                                    <th>Restraint</th>
                                    {col.map((year) => (
                                        <th key={year}>{year}</th>
                                    ))}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from(
                                    { length: count[currentPair[2] + 'Restraints'] },
                                    (v, i) => (
                                        <tr key={i}>
                                            <td className='align-middle'>{i + 1}</td>
                                            <td>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-sm'
                                                    value={
                                                        driverRestraintNames[
                                                        currentPair[2] + 'Restraints'
                                                        ][i]
                                                    }
                                                    onChange={(e) =>
                                                        handleDriverRestraintNameChange(
                                                            currentPair[2] + 'Restraints',
                                                            i,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </td>
                                            {col.map((year) => (
                                                <td key={year}>
                                                    <select
                                                        className='form-select '
                                                        value={
                                                            driverRestraintValues[
                                                            currentPair[2] + 'Restraints'
                                                            ][i]?.[year] || 'No Impact'
                                                        }
                                                        onChange={(e) =>
                                                            handleDriverRestraintChange(
                                                                currentPair[2] + 'Restraints',
                                                                i,
                                                                year,
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <option>No Impact</option>
                                                        <option>Very Low</option>
                                                        <option>Low</option>
                                                        <option>Low/Medium</option>
                                                        <option>Medium</option>
                                                        <option>Medium/High</option>
                                                        <option>High</option>
                                                        <option>Very High</option>
                                                    </select>
                                                </td>
                                            ))}
                                            {isProjectEditable && <td className='align-middle'>
                                                <i
                                                    onClick={() =>
                                                        deleteRow(currentPair[2] + 'Restraints', i)
                                                    }
                                                    style={{ color: 'red' }}
                                                    className='fa fa-trash font16'
                                                ></i>
                                            </td>}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                        {isProjectEditable && <div className='d-flex justify-content-center'>
                            <button
                                type='button'
                                className='btn btn-outline-secondary btn-sm mb-3 rounded-0'
                                onClick={() => {
                                    addRow(currentPair[2] + 'Restraints')
                                }}
                            >
                                <i className='fa fa-plus me-1'></i>
                                Add Line
                            </button>
                        </div>}
                        {isProjectEditable && <div className='d-flex justify-content-end align-items-center'>
                            <span style={{ color: 'red' }} className='mt-3 me-2 font14'>
                                *Save each section to prevent data loss
                            </span>
                            <div class="gp-action-btn-part me-2 mt-3">
                                <button type="button" class="gp-btn-fill" onClick={stepTwo} >Save</button>
                            </div>
                        </div>}
                    </div>
                )}

            </div>
        </>
    )
}

export default OthersEditableDandR