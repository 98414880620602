import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../CSS/SideBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature, faGear } from '@fortawesome/free-solid-svg-icons';

const Sidebar = (data) => {
    const navigate = useNavigate();
    const NavigateToPage = (e) => {
        navigate(`/${e}`)

    }
    const getUserDetailsFromLocalStorage = () => {
        const userDetailsString = sessionStorage.getItem('userDetails');
        if (userDetailsString) {
            return JSON.parse(userDetailsString);
        } else {
            return null;
        }
    };

    return (
        <div id="sidebar" className={!data?.data ? "active" : ""}>

            <div style={{ top: '16px' }} className='sticky-top'>
                <div className='my-3 border-bottom' onClick={() => NavigateToPage("")}>
                    {!data?.data ? <span className='sitename ms-3 text-white' style={{ fontSize: '1.6rem' }}>Growth Opportunity</span> : null}
                </div>
                <ul className="list-unstyled components">
                    {!data?.data ? (
                        <li>
                            <a
                                onClick={() => {
                                    data?.receiveState();
                                    NavigateToPage("project");
                                }}
                                href="#"
                                className="d-flex align-items-center"
                            >
                                <i className="fa fa-list" style={{ fontSize: '25px', marginRight: '10px' }}></i>
                                <span style={{ fontSize: '15px' }}>My Projects</span>
                            </a>
                        </li>
                    ) : null}
                    {/* <li>
              <a
                  onClick={() => {
                      data?.receiveState();
                      NavigateToPage("mytest");
                  }}
                  href="#"
                  className="d-flex align-items-center"
              >
                  <FontAwesomeIcon icon={faFileSignature} style={{ fontSize: '25px', marginRight: '10px' }} />
                  <span style={{ fontSize: '15px' }}>Creativity Tool</span>
              </a>
          </li> */}

                    {getUserDetailsFromLocalStorage()?.role == 'admin' && <li>
                        <a
                            onClick={() => {
                                data?.receiveState();
                                NavigateToPage("tocThemeConfig");
                            }}
                            href="#"
                            className="d-flex align-items-center"
                        >
                            <FontAwesomeIcon icon={faGear} style={{ fontSize: '25px', marginRight: '10px' }} />
                            <span style={{ fontSize: '15px' }}>TOC Theme Config</span>
                        </a>
                    </li>}

                </ul>
            </div>
        </div>
    );
};

export default Sidebar;


