import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useCreateCloneTocMutation, useDeleteCloneMutation, useGetGrowthMetricDatabyProjectIdQuery, useGetTocDataQuery, usePostGrowthMetricsDataMutation, usePostTocDataMutation, useReCallMetricMutation, useUpdateIsApplicableMutation, useUpdateTocIsSavedMutation } from '../../redux/slices/ProjectPageAPISlice';
import { GrowthMetricsStaticData } from '../../AppStaticData/ProjectPageStaticData';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import SSOLoader from '../SSOLoader';
import GrowthMetricCard from '../GrowthMetrics/GrowthMetricCard';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import { ProgressContext } from '../../Providers/ProgressProvider';
import Footer from './Footer';
import high from "../../images/high.png";
import medium from "../../images/medium.png";
import low from "../../images/low.png";
import DialogueBox from '../../ComponentUtils/DialogBox';
import '../../layout/CSS/previewPage.css'
import '../../assets/css/style.css'
import wrong from '../../images/no.png'
import instruction from '../../images/instruction.svg'

const FilterButton = ({ selectedImpact, impact, label, className, onClick }) => (
    <button
        className={`btn btn-sm ${selectedImpact === impact ? className : `btn-outline-${className.split('-')[1]}`} me-2`}
        onClick={() => onClick(impact)}
    >
        {label}
    </button>
);


function GrowthMetrics(props) {
    console.log(props)
    const { projectId } = useParams();
    const { handleSectionSave } = useContext(ProgressContext)
    const { data,
        isSuccess, isLoading, refetch
    } = useGetGrowthMetricDatabyProjectIdQuery({
        projectId: projectId,
        segmentId: props.seg_id,
        tocId: props.id
    });
    const [ReCallMetric] = useReCallMetricMutation();
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const [postTocData] = usePostTocDataMutation();
    const [updateTocIsSaved] = useUpdateTocIsSavedMutation();
    const [postGrowthMetricsData] = usePostGrowthMetricsDataMutation()
    const [growthMetrics, setGrowthMetrics] = useState([]);
    const [selectedImpact, setSelectedImpact] = useState(null);
    const [loader, setLoader] = useState(false);
    const [headerIsEdit, setHeaderIsEdit] = useState(false);
    const [header, setHeader] = useState("");
    const [titleData, setTitleData] = useState('')

    useEffect(() => {
        if (isSuccess) {
            setGrowthMetrics(data?.data.length ? data.data : GrowthMetricsStaticData.data);
        }
    }, [data, isSuccess]);

    useEffect(() => {
        setHeader(props?.name);
        setTitleData(props?.subtitle)
    }, [props]);

    const addMetric = () => {
        const newId = growthMetrics.length + 1;
        setGrowthMetrics([...growthMetrics, { id: newId, name: 'New Metric' }]);
    };

    const deleteMetric = (id) => {
        setGrowthMetrics(prevMetrics => prevMetrics.filter(metric => metric.id !== id));
    };

    const updateMetricName = (index, field, value) => {

        const newGrowthMetricsData = growthMetrics.map((metric, i) => {
            if (i === index) {
                return { ...metric, [field]: value };
            }
            return metric;
        });
        setGrowthMetrics(newGrowthMetricsData);
    };

    const updateMetricImpact = (id, impact) => {
        setGrowthMetrics(prevMetrics =>
            prevMetrics.map(metric =>
                metric.id === id ? { ...metric, impact: metric.impact === impact ? null : impact } : metric
            )
        );
    };

    const saveTitle = async () => {
        try {
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    'subtitle': titleData
                }
            })
            refetch();
            ReCallMetric();
            // updateTocIsSaved({ projectId: projectId, tocId: props.id, isSaved: 0 })

        } catch (error) {
            console.log(error)
        }
    }

    const toggleImpactFilter = (impact) => {
        setSelectedImpact(prevImpact => prevImpact === impact ? null : impact);
    };



    const filteredMetrics = useMemo(() =>
        selectedImpact ? growthMetrics.filter(metric => metric.impact === selectedImpact) : growthMetrics,
        [selectedImpact, growthMetrics]);


    const postGrowthMetricData = async () => {
        try {
            setLoader(true);
            await postGrowthMetricsData({
                projectId: projectId,
                body: {
                    "userid": getUserDetailsFromLocalStorage().user_id,
                    "seg_id": props.seg_id,
                    "metrics": growthMetrics,
                    "tocid": props.id
                }
            }).then((res) => {
                if (res.data.message == 'Successfully saved') {
                    toast.success(res.data.message);
                }
                refetch();
                ReCallMetric();
            })
            updateTocIsSaved({ projectId: projectId, tocId: props.id, isSaved: 1 })
            handleSectionSave(props.id)

        } catch (error) {
            console.error(error)
        } finally {
            setLoader(false)
        }

    }

    return (
        <>


            <div className='d-flex justify-content-between mt-2 me-2' >
                <button type="button" className='infoButton m-1'>
                    <img src={instruction} className='go-img' alt="instruction" />
                    <div class="gp-popover-infos">
                        <ul style={{ listStyleType: 'circle' }}>
                            <li>
                                Add Title and click save to avoid data loss
                            </li>
                            <li>
                                Add the assessed growth metrics and click save to avoid data loss
                            </li>
                            <li>
                                Only the metrices saved, will be displayed in the preview/output,Empty metrices columns will not be considered
                            </li>
                            <li>
                                By default 10 metrices will be available,user can edit or delete metrices                            </li>
                            <li>
                                By clicking on Add new button ,new metrice will be added
                            </li>
                        </ul>
                    </div>
                </button>
                <p className='d-flex' style={{ color: 'red', backgroundColor: 'yellow' }}>Auto&nbsp;save <img className='ms-2 mt-1' style={{ height: '15px' }} src={wrong} /></p>

            </div>

            <div class="h-100 d-flex flex-column gp-editor-wrapper w-100">
                <div class="w-100 h-100 flex-grow-1 position-relative gp-editor">
                    <div class="w-100 h-100 gp-competitorSection gp-editing-content">

                        <div class="h-auto gp-competitor-header">
                            <p>{filteredMetrics?.length} lines found</p>
                            {
                                (isProjectEditable && filteredMetrics.length < 10) && (<button type="button" onClick={addMetric}>
                                    <span class="mb-0">+ Add New</span>
                                </button>)
                            }

                        </div>

                        <div class="d-flex justify-content-between">
                            <div class="w-50">
                                <label for="projectType" class="gp-field-label" title="Enter the approved title as created in Production Plan">Title
                                    <span>*</span></label>
                                <input id="title"
                                    value={titleData} name='title_name' placeholder="(Sample - Growth Opportunities in Battery Sector)"
                                    class="w-100 gp-input-frost2"
                                    onChange={(e) => setTitleData(e.target.value)}
                                />
                            </div>
                            <div className="d-flex justify-content-end">
                                <div className="gp-action-btn-part mt-5">
                                    <button
                                        type="button"
                                        className="gp-btn-fill"
                                        onClick={saveTitle}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>



                        <div class="gp-metric-body " >


                            {filteredMetrics.map((metric, i) => (
                                <GrowthMetricCard
                                    key={i}
                                    index={i}
                                    metric={metric}
                                    updateMetricName={updateMetricName}
                                    deleteMetric={deleteMetric}
                                    updateMetricImpact={updateMetricImpact}
                                />
                            ))}


                        </div>



                        {isProjectEditable &&
                            <div className='d-flex justify-content-end'>
                                <div class="gp-action-btn-part mt-1">
                                    <button type="button">Reset</button>
                                    <button type="button" class="gp-btn-fill" onClick={postGrowthMetricData} >Save</button>
                                </div>
                            </div>
                        }

                    </div>
                </div>

                <Footer />
            </div>


        </>
    );
}

export default GrowthMetrics;