import axios from 'axios'
import { BrowserRouter as Router } from 'react-router-dom';
import { useEffect, useState } from "react"
import FrostSkeleton from './layout/js/FrostSkeleton';
import Routes from './routes/Routes';
import SSOLoader from './Components/SSOLoader';

import { useDispatch } from 'react-redux';
import { setUser } from './redux/slices/UserSlice';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import HealthPage from './HealthPage';
import "./assets/css/Index.css"

function App() {
  const [userDetails, setUserDetails] = useState(null);
  const cookieValue = document?.cookie?.split(';')?.find(token => token.trim().startsWith("$access_token"))?.split('=')[1]
  const dispatch = useDispatch();
  var role;

  const getRole = (slug) => {
    switch (slug) {
      case "growth_opportunity_analyst":
        return "analyst";
      case "radar_project_manager":
        return "pm";
      case "radar_editor":
        return "editor";
      case "editor_sub":
        return "subeditor";
      case "radar_qc_ep":
        return "qc";
      case "super_admin":
        return "admin";
      default:
        return "Unknown";
    }
  }

  async function getCookies() {
    const url = 'https://accountsuat.frost.com/api/v1/auth/'
    const headers = {
      Authorization: `Bearer ${cookieValue}`,
    }
    try {
      let response = await axios.get(url, { headers })
      response?.data?.data?.groups?.forEach(obj => {
        const defineRole = getRole(obj.slug);
        if (defineRole !== "Unknown") {
          role = defineRole
        }
      });

      setUserDetails({
        'name': response?.data?.data?.person?.name,
        'image': response?.data?.data?.person?.photo,
        'user_id': response?.data?.data?.person?.user_id,
        'role': role
      })
      sessionStorage.setItem('userDetails', JSON.stringify({
        'name': response?.data?.data?.person?.name,
        'image': response?.data?.data?.person?.photo,
        'user_id': response?.data?.data?.person?.user_id,
        'role': role
      }));
      localStorage.setItem('userDetails', JSON.stringify({
        'name': response?.data?.data?.person?.name,
        'image': response?.data?.data?.person?.photo,
        'user_id': response?.data?.data?.person?.user_id,
        'role': role
      }));

      

      // window.postMessage({ name: "John Doe", role: "admin" }, "http://localhost:3001");
    } catch (error) {
      //console.log(process.env.REACT_APP_LOGIN_URL)
      window.location.href = `${process.env.REACT_APP_GO_IN_BASE_URL}/ssologin`;
      //window.location.href = `${process.env.REACT_APP_LOGIN_URL}`
    }
  }

  useEffect(() => {
    if (!userDetails && window.location.href.split('/').pop() != 'health') { getCookies() }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails) {
      dispatch(setUser(userDetails))
    }
  }, [userDetails]);

  // useEffect(() => {
  //   const sessionData = {
  //     name: 'response?.data?.data?.person?.name',
  //     image: 'response?.data?.data?.person?.photo',
  //     user_id: 'response?.data?.data?.person?.user_id',
  //     role: 'role'
  //   };
  //   window.addEventListener("load", () => {
  //     const targetOrigin = process.env.REACT_APP_TECHVISION_JS;
  //     console.log(targetOrigin);

  //     window.postMessage(sessionData, targetOrigin);

  //   });
  // }, [])

  if (!userDetails && window.location.href.split('/').pop() != 'health') {
    return <SSOLoader />;
  } else if (window.location.href.split('/').pop() == 'health') {
    return <HealthPage />
  } else {
    return (
      <Router basename="/">
        <ToastContainer style={{ fontSize: '2.0rem' }}/>
        <FrostSkeleton userDetails={userDetails}>
          <Routes />
        </FrostSkeleton>
      </Router>
    );
  }


}

export default App;
