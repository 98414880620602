import React, { useContext, useEffect, useRef, useState } from 'react';
import Node from './Node';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useParams } from 'react-router-dom';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import {  useEditSegmentationNodeMutation, useGetDNRDataQuery } from '../Store/dataSlices/DriverAndRestraintAPISlice';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { useGetSegmentationTypeQuery } from '../../redux/slices/ProjectPageAPISlice';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import { SegmentationTreeContext } from '../Provider/SegmentationTreeProvider';


const EvenLevelNode = ({ node, onAddChild, onDeleteNode, onNameChange,isForecastCompleted }) => {
    const [nodeId,setNodeId]=useState(node.id)
    const {projectId}=useParams();
    const {data,isLoading,isSuccess}=useGetSegmentationTypeQuery({projectId:projectId})
    const {isProjectEditable}=useContext(ProjectStatusContext)
    const [segmentName,setSegmentName]=useState(node.name);
    const { data: DnrData, error, isError, isLoading:isDNRdataLoading, isSuccess:isDNRdataSuccess, } = useGetDNRDataQuery({ projectId, userId: getUserDetailsFromLocalStorage().user_id });
    const {isNodeEditable}=useContext(SegmentationTreeContext)
    useEffect(()=>{
        setSegmentName(node?.name||'')
    },[isLoading,isSuccess,node])
    const [editSegmentationNode]=useEditSegmentationNodeMutation()
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
      };

      const findNodesAtLevel = (nodes, targetLevel,targetSource) => {
        const nodesAtLevel = [];
    
        const traverse = (node) => {
            if (node.level === targetLevel && node.source===targetSource) {
                nodesAtLevel.push(node.name);
            }
            (node.children || []).forEach(child => traverse(child));
        };
    
        nodes.forEach(node => traverse(node));
    
        return nodesAtLevel;
    };
    const handleClose=()=>{
        setOpen(false);
    }
    const handleEditSegmentName=async()=>{
        if(segmentName.length===0){
            toast.error("Segment name cannot be empty")
            return
        }
        if(segmentName.length<2){
            toast.error("Minimum length of Segment name should be 4")
            return
        }
        if(node.name===segmentName) return;
        const nodesAtTargetLevel = findNodesAtLevel(DnrData?.initialNodes, node.level,node.source);
        const isDuplicate = nodesAtTargetLevel.includes(segmentName)

        if (isDuplicate) {
            toast.error("This segment name already exists at this level. Please choose a different name.");
            return;
        }
        const nodeData={
            id:node.id,
            user_id : getUserDetailsFromLocalStorage().user_id,
            name: segmentName,
            source:node.source
        }
        try {
            await editSegmentationNode({projectId:projectId,body:nodeData})
        } catch (error) {
            console.log(error)
        } finally{
            toast.success("Segment added")
        }
        setOpen(false)
    }
    function shouldRenderEditIcon(isForecastCompleted, isProjectEditable, isNodeEditable, nodeLevel) {
        return ((!isForecastCompleted && isProjectEditable) || isNodeEditable) && nodeLevel !== 0;
    }

    const inputNodeRef=useRef(null)
    const [openTextfield,setOpenTextField]=useState(true)
  
    const onFocusOutHandler = async () => {
        
        try {
          setOpenTextField(false); 
          await handleEditSegmentName(); 
        } catch (error) {
          console.error("Error handling focus out:", error);
          toast.error("Failed to update segment name");
        } finally {
          setSegmentName(node.name || ""); 
        }
      };
      
    useEffect(() => {
        if (openTextfield && inputNodeRef.current) {
            inputNodeRef.current.focus();
        }
    }, [openTextfield]);
    return (
        <div className='org-node'>
            <div className='org-details'>
                <div className={((!isForecastCompleted&&isProjectEditable) || isNodeEditable? 'd-flex justify-content-between align-items-center' : 'text-center')}>
                    {(!isForecastCompleted&&isProjectEditable) || isNodeEditable  ?
                        <i 
                        onClick={() => onAddChild(node.id)} 
                        title='Add Segment' style={{fontSize:"1.6rem"}}
                        className="fa fa-plus-circle me-1"></i>:null}
                    <div className=''>
                        
                        <span 
                        className='nodename'>
                            {openTextfield?
                        <input 
                            ref={inputNodeRef}
                            value={segmentName} 
                            onBlur={onFocusOutHandler}
                            onChange={(e)=>setSegmentName(e.target.value)}
                            className="form-control form-control-sm"
                            placeholder="Add a segment"
                            autoFocus
                        />
                        :node.name} 
                         {shouldRenderEditIcon(isForecastCompleted, isProjectEditable, isNodeEditable, node.level) ? (
                        !openTextfield?<i title='Edit Segment' className='fa fa-edit ms-1 position-relative' onClick={()=>setOpenTextField(true)} style={{ cursor: 'pointer',top:'1px' }}></i>:null
                    ) : null}
                        </span>
                    </div>
        {/* <Dialog
            open={open}
            onClose={handleClose}
            
        >
            <DialogTitle>Create Segment</DialogTitle>
            <DialogContent>
            <DialogContentText>
            Rename this node
            </DialogContentText>
            <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                name="segmentName"
                label="New Segment name"
                type="text"
                fullWidth
                variant="standard"
                value={segmentName}
                onChange={(e)=>setSegmentName(e.target.value)}
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" onClick={handleEditSegmentName}>Confirm</Button>
            </DialogActions>
        </Dialog> */}
                    {(!isForecastCompleted&&isProjectEditable) || isNodeEditable ?<i onClick={() => onDeleteNode(node.id)} title='Remove Segment' style={{fontSize:"1.6rem"}} className="fa fa-minus-circle ms-1"></i>:null}
                </div>
            </div>
            {node?.children?.length > 0 && (
                <div className='org-children'>
                    {node.children.map((child) => (
                        <Node
                            key={child.id}
                            node={child}
                            onAddChild={onAddChild}
                            onDeleteNode={onDeleteNode}
                            onNameChange={onNameChange}
                        />
                    ))}
                </div>
            )}
            
        </div>
    );
};

export default EvenLevelNode;
