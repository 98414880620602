import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

export const ProgressContext = createContext();

export const ProgressProvider = ({ children, initialSections }) => {
  const [completedSections, setCompletedSections] = useState(new Set());

  const countIsSaved = useMemo(() => {
    return initialSections.reduce(
      (counts, item) => {
        if (item.isApplicable === 1 && item.isMandatory === 1) {
          counts.applicableAndMandatory++;
          if (item.isSaved === 1) {
            counts.saved++;
            setCompletedSections(prevCompletedSections => new Set(prevCompletedSections).add(item.id));
          }
          
        }
        return counts;
      },
      { saved: 0, applicableAndMandatory: 0 }
    );
  }, [initialSections]);
  
  
    const [totalSections, setTotalSections] = useState(countIsSaved.applicableAndMandatory);
    const [savedSections, setSavedSections] = useState(countIsSaved.saved);
    
    const [currentStage, setCurrentStage] = useState(1); 
    
    useEffect(() => {
        setTotalSections(countIsSaved.applicableAndMandatory);
        setSavedSections(countIsSaved.saved)
      }, [initialSections,countIsSaved]);
      
      const handleSectionSave = useCallback((sectionId) => {
        setCompletedSections(prevCompletedSections => {
          if (!prevCompletedSections.has(sectionId)) {
            const item=initialSections.find((item)=>item.id===sectionId)
            if(item.isApplicable&&item.isMandatory){
              const newCompletedSections = new Set(prevCompletedSections);
              newCompletedSections.add(sectionId);
              setSavedSections(newCompletedSections.size);
              return newCompletedSections;
            }
          }
          return prevCompletedSections;
        });
      }, [completedSections,totalSections,initialSections]);
  
  const calculateProgressPercentage = useCallback(()=>{
    if (totalSections === 0 && savedSections === 0) {
      return 0;
    }
    else if(((savedSections / totalSections) )>1) return 100;
    return ((savedSections / totalSections) * 100).toFixed(0);
  },[totalSections,savedSections,completedSections]);

  const nextStage = () => {
    setCurrentStage((prevStage) => prevStage + 1);
  };

  const previousStage = () => {
    setCurrentStage((prevStage) => (prevStage > 1 ? prevStage - 1 : 1));
  };

  const progressContextValue = useMemo(
    ()=>{
        return {
            savedSections,
            currentStage,
            handleSectionSave,
            calculateProgressPercentage,
            totalSections,
            nextStage,
            previousStage,
            completedSections
          }
    },[savedSections, currentStage, totalSections,initialSections,completedSections]);

  return (
    <ProgressContext.Provider value={progressContextValue}>
      {children}
    </ProgressContext.Provider>
  );
};