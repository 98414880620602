import React, { useContext, useEffect, useState } from 'react'
import { useCreateCloneTocMutation, useDeleteCloneMutation, useGetBPRDataQuery, useGetGroupByUserListQuery, usePostBPRDataMutation, usePostTocDataMutation, useUpdateIsApplicableMutation, useUpdateTocIsSavedMutation } from '../../redux/slices/ProjectPageAPISlice'
import { useParams } from 'react-router-dom'
import AwardRow from '../BPRAward/AwardRow';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils'
import { Autocomplete, TextField, Typography } from '@mui/material';
import SSOLoader from '../SSOLoader';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import { ProgressContext } from '../../Providers/ProgressProvider';
import Footer from './Footer';

const GoInBestPracticeRecognition = (props) => {
    const { handleSectionSave } = useContext(ProgressContext)
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const { projectId } = useParams();
    const [updateTocIsSaved] = useUpdateTocIsSavedMutation();
    const [loader, setLoader] = useState(false);
    const { data, isLoading, isError, isSuccess } = useGetBPRDataQuery({ projectId: projectId, tocId: props.id })
    const { data: bprAnalystData,
        isLoading: isBprAnalystLoading,
        isSuccess: isBprSuccess
    } = useGetGroupByUserListQuery({ slug: 'growth_opportunity_analyst' });
    const { data: pmListData,
        isLoading: isPmDataLoading,
        isSuccess: isPmDataSuccess
    } = useGetGroupByUserListQuery({ slug: 'radar_project_manager' })
    const [postBPRData] = usePostBPRDataMutation()
    const [analystList, setAnalystList] = useState([]);
    const [pmList, setPmList] = useState([]);

    const [selectedPm, setSelectedPm] = useState(null);
    const [selectedAnalyst, setSelectedAnalyst] = useState(null)
    useEffect(() => {
        if (isBprSuccess) {
            const transformAnalystList = bprAnalystData?.data.map((item) => ({
                email: item.email,
                user_id: item.user_id
            }))
            setAnalystList(transformAnalystList)
        }
    }, [isBprAnalystLoading, isBprSuccess])

    useEffect(() => {
        if (isPmDataSuccess) {
            const transformPmList = pmListData?.data.map((item) => ({
                email: item.email,
                user_id: item.user_id
            }))
            setPmList(transformPmList)

        }
    }, [isPmDataLoading, isPmDataSuccess, , isSuccess, isLoading])
    const userID = getUserDetailsFromLocalStorage().user_id
    const [bprId, setBprId] = useState(null);
    const [awardsData, setAwardsData] = useState([
        { recipient: '', company_contact: '', contact_title: '', contact_phone: '', contact_email: '', award_category: '', Region: '', market: '', bpr_support: '', comments: '' },
        { recipient: '', company_contact: '', contact_title: '', contact_phone: '', contact_email: '', award_category: '', Region: '', market: '', bpr_support: '', comments: '' },
        { recipient: '', company_contact: '', contact_title: '', contact_phone: '', contact_email: '', award_category: '', Region: '', market: '', bpr_support: '', comments: '' },
    ]);
    useEffect(() => {
        if (isSuccess && data?.data?.criteria?.length) {
            const transformedData = data?.data?.criteria?.map(item => ({
                recipient: item.recipient,
                company_contact: item.company_contact,
                contact_title: item.contact_title,
                contact_phone: item.contact_phone,
                contact_email: item.contact_email,
                award_category: item.award_category,
                Region: item.Region,
                market: item.market,
                bpr_support: item.bpr_support,
                comments: item.comments
            }));

            setAwardsData(transformedData)
            setBprId(data?.data.id)

        }
        else {
            setAwardsData([
                { recipient: '', company_contact: '', contact_title: '', contact_phone: '', contact_email: '', award_category: '', Region: '', market: '', bpr_support: '', comments: '' },
                { recipient: '', company_contact: '', contact_title: '', contact_phone: '', contact_email: '', award_category: '', Region: '', market: '', bpr_support: '', comments: '' },
                { recipient: '', company_contact: '', contact_title: '', contact_phone: '', contact_email: '', award_category: '', Region: '', market: '', bpr_support: '', comments: '' },
            ])

        }
    }, [data, isLoading, isSuccess])
    useEffect(() => {
        if (isSuccess && isBprSuccess) {

            const selectedItem = analystList.find((item) => item.user_id === data?.data.analyst_name)
            setSelectedAnalyst(selectedItem)
        }

    }, [isBprAnalystLoading, isBprSuccess, isSuccess, analystList])

    useEffect(() => {
        if (isSuccess && isPmDataSuccess) {
            const selectedItem = pmList.find((item) => item.user_id === data?.data.pm_name)
            setSelectedPm(selectedItem)
        }
    }, [isPmDataLoading, isPmDataSuccess, isSuccess, pmList])

    const handleChange = (index, field, value) => {
        console.log("dfsdf");
        
        const newAwards = [...awardsData];
        newAwards[index][field] = value;
        console.log(newAwards);
        
        setAwardsData(newAwards);
    };
    const [validationErrors, setValidationErrors] = useState("");

    function handleValidate() {
        const errors = awardsData.map((award, index) => {
            const isEmpty = Object.values(award).every(value => value === '');
            return isEmpty ? (`Award ${index + 1} must have at least one field filled.`) : '';
        }).filter(error => error !== '');
        setValidationErrors(errors);
        const toastIds = errors.map(error => toast.error(error));

        setTimeout(() => {
            toastIds.forEach(toastId => {
                if (toast.isActive(toastId) && toast.dismiss) {
                    toast.dismiss(toastId);
                }
            });
        }, 3000);
        return errors.length === 0;
    }

    const handleBPRsave = async () => {
        if (!selectedAnalyst) {
            toast.error("Please add an Analyst")
        }
        if (!selectedPm) {
            toast.error("Please add Project Manager")
        }
        if (handleValidate()) {
            postBPRData({
                projectId: projectId,
                body: bprId ? {
                    userid: userID,
                    analyst_id: selectedAnalyst.user_id,
                    pm_id: selectedPm.user_id,
                    bpr_id: bprId,
                    bpraward: awardsData,
                    tocid: props.id
                } : {
                    userid: userID,
                    analyst_id: selectedAnalyst.user_id,
                    pm_id: selectedPm.user_id,
                    bpraward: awardsData,
                    tocid: props.id
                }
            }).then((res) => {
                toast.success('Form submitted successfully');
            }).catch((err) => console.log(err))
            updateTocIsSaved({ projectId: projectId, tocId: props.id ,isSaved:1})
            handleSectionSave(props.id)
        }


    }

    const handleClear = () => {
        setAwardsData([
            { recipient: '', company_contact: '', contact_title: '', contact_phone: '', contact_email: '', award_category: '', Region: '', market: '', bpr_support: '', comments: '' },
            { recipient: '', company_contact: '', contact_title: '', contact_phone: '', contact_email: '', award_category: '', Region: '', market: '', bpr_support: '', comments: '' },
            { recipient: '', company_contact: '', contact_title: '', contact_phone: '', contact_email: '', award_category: '', Region: '', market: '', bpr_support: '', comments: '' },
        ])
        setSelectedAnalyst(null);
        setSelectedPm(null)
    }

  

    return (
        <>
            {loader ? <SSOLoader /> : null}
            <div class="w-100 h-100 flex-grow-1 position-relative gp-editor">
                <div class="w-100 h-100 gp-formSection gp-editing-content">
                    <div class="h-auto gp-form-header">
                        <div class="gp-select-wrapper">
                            <label for="analyst-name">Analyst Name</label>
                            {/* <select id="analyst-name">
                                <option value="">dilip.sarangan@frost.com</option>
                            </select> */}
                            <Autocomplete
                                disablePortal
                                disabled={!isProjectEditable}
                                options={analystList}
                                value={selectedAnalyst}
                                onChange={(event, newValue) => setSelectedAnalyst(newValue)}
                                getOptionLabel={(option) => option.email}
                                renderInput={(params) =>
                                    <TextField disabled={!isProjectEditable} {...params} label="Search by email" />
                                }
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        <Typography >{option.email}</Typography>
                                    </li>
                                )}
                            />
                        </div>
                        <div class="gp-select-wrapper">
                            <label for="pm-name">PM Name</label>
                            <Autocomplete
                                disablePortal
                                disabled={!isProjectEditable}
                                value={selectedPm}
                                onChange={(event, newValue) => setSelectedPm(newValue)}
                                options={pmList}
                                getOptionLabel={(option) => option.email}
                                renderInput={(params) =>
                                    <TextField disabled={!isProjectEditable} {...params} label="Search by email" />
                                }
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        <Typography >{option.email}</Typography>
                                    </li>
                                )}
                            />
                        </div>
                    </div>
                    <div class="gp-form-body">
                        <h3 class="gp-heading">3 Awards Required</h3>
                        {awardsData.map((item, i) => {
                            return <AwardRow
                                key={i}
                                index={i}
                                awardsData={item}
                                handleChange={handleChange}
                            />
                        })}
                    </div>
                    <div className='d-flex justify-content-end'>
                        <div class="gp-action-btn-part mt-2 me-4">
                            <button disabled={!isProjectEditable} type="button" onClick={handleClear} >Clear</button>
                            <button className="gp-btn-fill" disabled={!isProjectEditable} type="button" onClick={handleBPRsave} >Save</button>
                        </div>
                    </div>
                </div>
                        <Footer />
            </div>
        </>
    )
}



export default GoInBestPracticeRecognition