import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useCreateCloneTocMutation, useDeleteCloneMutation, usePostTocDataMutation, useUpdateIsApplicableMutation } from '../../redux/slices/ProjectPageAPISlice';
import SSOLoader from '../SSOLoader';
import CkEditorBase from '../../CkEditorReact/CkEditorBase';
import { ProgressContext } from '../../Providers/ProgressProvider';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { useParams } from 'react-router-dom';
import Footer from './Footer';
import DialogueBox from '../../ComponentUtils/DialogBox';
import tick from '../../images/tick.svg'
function AnalysisTocChild(props) {
    const { handleSectionSave } = useContext(ProgressContext)
    const [postTocData] = usePostTocDataMutation();
    const [loader, setLoader] = useState(false);
    const [description, setDescription] = useState("");
    const [headerIsEdit, setHeaderIsEdit] = useState(false);
    const [header, setHeader] = useState("");
    const [ckId, setCkId] = useState(null)
    const { projectId } = useParams();
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    useEffect(() => {
        setDescription(props?.description)
        setHeader(props?.name)
        setCkId(props?.id)
    }, [props])

    const handleChange = (e) => {
        let strippedText = e.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ');
        const characterCount = strippedText.length;
        // const wordCount = strippedText.split(/\s+/).filter(word => word.length > 0).length;


        if (characterCount < 1500) {
            setDescription(e)
            if (debounceTimeout) clearTimeout(debounceTimeout);
            const timeout = setTimeout(() => {
                handleSave(false, false, e)
            }, 1000);

            setDebounceTimeout(timeout);
        } else {
            toast.error('Only 1500 characters allowed!')
        }

    };

    const handleSave = async (headerSave = false, descriptionSave = false, data = '') => {
        try {
            setLoader(true)
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    "userid": getUserDetailsFromLocalStorage()?.user_id,
                    "description": data != '' ? data : descriptionSave ? description : props?.description,
                    "name": headerSave ? header : props?.name,
                    "isSaved": 1
                }
            }).then((res) => console.log(res))
            if (headerSave) setHeaderIsEdit(false)
            setLoader(false)
            handleSectionSave(props.id)
            toast.success('Saved Successfully')
        } catch (error) {
            console.log(error);
            setLoader(false)
            toast.error("Something went wrong")
        }
    }
    useEffect(() => {
        return () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
        };
    }, [debounceTimeout]);


    return (
        <>
            <div style={{paddingLeft: '90%'}}>
                <p className='m-2 d-flex justify-content-end ' style={{  position: 'fixed', color: 'red', backgroundColor: 'yellow' }}>Auto&nbsp;save <img className='ms-2' style={{ height: '15px' }} src={tick} /></p>
            </div>
            <div class="h-100 d-flex flex-column gp-editor-wrapper w-100">
                <div class="w-100 h-100 flex-grow-1 position-relative gp-editor">
                    <div class="w-100 h-100 gp-competitorSection gp-editing-content">
                        <div class="gp-metric-body my-4 w-100" >

                            {
                                ckId ? <CkEditorBase onChange={(data) => handleChange(data)} data={description} tocId={ckId} editorId={`toc-${ckId}`} Limits={[300, 1500, true]} /> : null
                            }
                        </div>

                    </div>
                </div>

                <Footer />
            </div>
        </>


    )
}

export default AnalysisTocChild