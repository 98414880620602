import React, { useState, useEffect, useRef, useContext } from 'react';
import { useGetDNRDataQuery } from '../../DriversAndRestraints/Store/dataSlices/DriverAndRestraintAPISlice';
import OthersEditableDandR from './Components/OtherEditableD&R';
import DisplayDandR from './Components/DisplayD&R';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import Footer from './Footer';
import { useGetDrDataQuery } from '../../redux/slices/ProjectPageAPISlice';
import wrong from '../../images/no.png'
import instruction from '../../images/instruction.svg'


function ScopeAnalysis(props) {
    const [type, setType] = useState("dr");
    const [priceDrivers, setpriceDrivers] = useState({});
    const [years, setYears] = useState([]);
    const projectId = props?.go_in_id;
    const { data: DnrData, error, isError, isLoading, isSuccess, } = useGetDNRDataQuery({ projectId, userId: getUserDetailsFromLocalStorage().user_id });
    const { data: DrData, refetch } = useGetDrDataQuery({ id: props?.go_in_id, seg_id: DnrData?.initialNodes[0]?.id, user_id: getUserDetailsFromLocalStorage().user_id });


    useEffect(() => {
        if (typeof (DrData) != "undefined") {
            setType(DrData?.data?.dr_type)
            if (DrData?.drivers_restrainsts_data?.priceDrivers) {
                setpriceDrivers(DrData?.drivers_restrainsts_data?.priceDrivers)
            } else { setpriceDrivers({}) }
        }
    }, [DrData])

    useEffect(() => {
        if (Object.keys(priceDrivers).length !== 0) {
            setYears(Object.keys(Object.values(priceDrivers)[0]))
        }
    }, [priceDrivers])




    return (
        <>
            <div className='d-flex justify-content-between' >
                <button type="button" className='infoButton m-1'>
                    <img src={instruction} className='go-img' alt="instruction" />
                    <div class="gp-popover-infos">
                        <ul style={{ listStyleType: 'circle' }}>
                            <li>
                                If DNR model is used ,No need to fill this section
                            </li>
                            <li>
                                If External model is used ,Fill these details appropriately
                            </li>
                            <li>
                                Auto save is not available
                            </li>
                        </ul>
                    </div>
                </button>
                <p className=' d-flex justify-content-end ' style={{ color: 'red', backgroundColor: 'yellow' }}>Auto&nbsp;save <img className='ms-2' style={{ height: '15px' }} src={wrong} /></p>

            </div>
            <div class="h-100 d-flex flex-column gp-editor-wrapper w-100">
                <div class="w-100 h-100 flex-grow-1 position-relative gp-editor">
                    <div class="w-100 h-100 gp-competitorSection gp-editing-content">
                        <div class="gp-metric-body my-4" >

                            {
                                type == "other" ? <OthersEditableDandR allData={DrData} years={years} refetch={() => { refetch() }} toc={props} type="priceDrivers" /> :
                                    <DisplayDandR unitDrivers={priceDrivers} years={years} type="drivers" />
                            }
                        </div>

                    </div>
                </div>

                <Footer />
            </div>
        </>

    )
}

export default ScopeAnalysis
