import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';



export const ProjectPageAPISlice = createApi({
    reducerPath: 'growthapi',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_GO_IN_BASE_URL }),
    tagTypes: ['goUApi', 'segmentType', 'completeSegmentation', 'tocApiRecall', 'updateStatus'],
    endpoints: (builder) => ({
        getProjectDetails: builder.query({
            query: ({ projectId }) => {
                return ({
                    url: `project/${projectId}?user_id=${getUserDetailsFromLocalStorage().user_id}`,
                    method: "GET",
                })
            },
            providesTags: ['completeSegmentation', 'updateStatus']
        }),
        getCriteriaData: builder.query({
            query: (type) => `/projectdet/gocriteria/`,

        }),
        postCriteriaData: builder.mutation({
            query: ({ body, eco_project_id }) => {

                return ({
                    url: `project/${eco_project_id}/go/save`,
                    method: 'POST',
                    body: body
                })
            },
            invalidatesTags: ['goUApi']
        }),
        getGrowthUniverseDataById: builder.query({
            query: ({ projectId, goInId }) => {

                return ({
                    url: `/project/${projectId}/go/get/${goInId}`,
                    method: 'GET',
                })
            },
            providesTags: ['goUApi'],

        }),

        deleteGouData: builder.mutation({
            query: ({ projectId, goInId }) => {
                return ({
                    url: `/project/${projectId}/go/delete/${goInId}`,
                    method: 'DELETE'
                })
            },
            invalidatesTags: ['goUApi']
        }),
        getSegmentationType: builder.query({
            query: ({ projectId }) => {
                return ({
                    url: `/project/${projectId}/segmentationtype`,
                    method: 'GET'
                })
            },
            providesTags: ['segmentType']
        }),
        postSegmentationType: builder.mutation({
            query: ({ projectId, body }) => {
                return ({
                    url: `project/${projectId}/segmentationtype/save`,
                    method: "POST",
                    body: body
                })
            },
            invalidatesTags: ['segmentType'],
        }),
        deleteSegmentationType: builder.mutation({
            query: ({ projectId, body }) => {
                return ({
                    url: `/project/${projectId}/segmentationtype/delete`,
                    method: 'DELETE',
                    body: body
                })
            },
            invalidatesTags: ['segmentType'],
        }),
        editSegmentationType: builder.mutation({
            query: ({ projectId, body }) => {
                return ({
                    url: `project/${projectId}/segmentationtype/save`,
                    method: 'POST',
                    body: body
                })
            },
            invalidatesTags: ['segmentType'],
        }),
        postCompleteSegmentation: builder.mutation({
            query: ({ projectId, body }) => {
                return ({
                    url: `project/${projectId}/forecaststatus`,
                    method: "POST",
                    body: body
                })
            },
            invalidatesTags: ['completeSegmentation']
        }),
        getTocData: builder.query({
            query: ({ projectId }) => {
                return ({
                    url: `/project/${projectId}/toccontent`,
                    method: "GET"
                })
            },
            providesTags: ['completeSegmentation', 'tocApiRecall']
        }),
        postTocData: builder.mutation({
            query: ({ projectId, propsId, body }) => {
                return ({
                    url: `/projectdet/tocprojectdata/update/${projectId}/${propsId}/`,
                    method: "POST",
                    body: body
                })
            },
            invalidatesTags: ['tocApiRecall']
        }),
        getBPRData: builder.query({
            query: ({ projectId, tocId }) => {
                return ({
                    url: `project/${projectId}/bpraward/get/${tocId}`,
                    method: "GET"
                })
            }
        }),
        deleteBPRData: builder.mutation({
            query: ({ projectId }) => {
                return ({
                    url: `project/${projectId}/bpraward/delete`,
                    method: "DELETE",

                })
            }
        }),
        postBPRData: builder.mutation({
            query: ({ projectId, body }) => {
                return ({
                    url: `project/${projectId}/bpraward/save`,
                    method: "POST",
                    body: body
                })
            }
        }),
        getGroupByUserList: builder.query({
            query: ({ slug }) => {
                return ({
                    url: `/groupsbyuser?slug_name=${slug}&email=`,
                    method: "GET"
                })
            }
        }),
        getKeyCompetitors: builder.query({
            query: ({ projectId, nodeId, tocId }) => {
                return ({
                    url: `projectdet/keycompetitors/get/${projectId}/${nodeId}/${tocId}`,
                    method: "GET"
                })
            }
        }),
        postKeyCompetitor: builder.mutation({
            query: ({ projectId, body }) => {
                return ({
                    url: `projectdet/keycompetitors/save/${projectId}`,
                    method: "POST",
                    body: body,
                })
            }
        }),
        postUpdateProjectStatus: builder.mutation({
            query: ({ projectId, body }) => {
                return ({
                    url: `project/${projectId}/updateStatus`,
                    method: 'POST',
                    body: body
                })
            },
            invalidatesTags: ['updateStatus']
        }),
        getContributorsList: builder.query({
            query: ({ slugName }) => {
                return ({
                    url: `groupsbyuser?slug_name=${slugName}`,
                    method: "GET"
                })
            }
        }),
        getGrowthMetricDatabyProjectId: builder.query({
            query: ({ projectId, segmentId, tocId }) => {
                return ({
                    url: `projectdet/growthmetrics/get/${projectId}/${segmentId}/${tocId}`,
                    method: "GET"
                })
            }
        }),
        ReCallMetric: builder.mutation({
            query: () => ({
                url: '', 
                method: 'GET',
            }),
            invalidatesTags: ['tocApiRecall']
        }),
        postContributorsData: builder.mutation({
            query: ({ projectId, body }) => {
                return ({
                    url: `project/${projectId}/addcontributor`,
                    method: "POST",
                    body: body
                })
            }
        }),
        postGrowthMetricsData: builder.mutation({
            query: ({ projectId, body }) => {
                return ({
                    url: `projectdet/growthmetrics/save/${projectId}`,
                    method: "POST",
                    body: body
                })
            }
        }),
        getDrData: builder.query({
            query: ({ id, seg_id, user_id }) => ({
                url: `project/${id}/getdrdata`,
                method: 'GET',
                params: { seg_id, user_id },
            }),
        }),
        updateTocIsSaved: builder.mutation({
            query: ({ projectId, tocId ,isSaved}) => {
                return ({
                    url: `/projectdet/tocprojectdata/update/${projectId}/${tocId}`,
                    method: "POST",
                    body: {
                        isSaved: isSaved 
                    }
                })
            }
        }),
        updateIsApplicable: builder.mutation({
            query: ({ projectId, tocId, isApplicable }) => {
                return ({
                    url: `/projectdet/tocprojectdata/update/${projectId}/${tocId}`,
                    method: "POST",
                    body: {
                        userid: getUserDetailsFromLocalStorage().user_id,
                        isApplicable: isApplicable
                    }
                })
            },
            invalidatesTags: ['tocApiRecall']
        }),
        createCloneToc: builder.mutation({
            query: ({ projectId, tocId, type }) => {
                var data;
                if (type) {
                    data = {
                        "userid": getUserDetailsFromLocalStorage().user_id,
                        "type": type
                    }
                } else {
                    data = {
                        "userid": getUserDetailsFromLocalStorage().user_id,
                    }
                }
                return ({
                    url: `/projectdet/tocclone/${projectId}/${tocId}`,
                    method: "POST",
                    body: data
                })
            },
            invalidatesTags: ['tocApiRecall']
        }),
        deleteClone: builder.mutation({
            query: ({ projectId, tocId }) => {
                return ({
                    url: `/projectdet/tocdelete/${projectId}/${tocId}`,
                    method: "POST",
                    body: {
                        "userid": getUserDetailsFromLocalStorage().user_id,
                        "isDeleted": 1 // 1 - delete, 0 - make live
                    }
                })
            },
            invalidatesTags: ['tocApiRecall']
        }),
        getEditorData: builder.query({
            query: ({ projectId }) => {
                return ({
                    url: `/project/${projectId}/ckeditordata`,
                    method: "GET",

                })
            }
        }),
        getProjectVersionList: builder.query({
            query: ({ projectId }) => {
                return ({
                    url: `/project/${projectId}/listallversions`,
                    method: "GET"
                })
            },
            providesTags: ['versionList']
        }),
        createProjectVersion: builder.mutation({
            query: ({ projectId, Name, Description }) => {
                return ({
                    url: `/project/${projectId}/createversion`,
                    method: "POST",
                    body: {
                        userid: getUserDetailsFromLocalStorage().user_id,
                        version_name: Name,
                        version_description: Description
                    }
                })
            },
            invalidatesTags: ['versionList']
        }),
        deleteProjectVersion: builder.mutation({
            query: ({ projectId }) => {
                return ({
                    url: `/project/${projectId}/deleteversion`,
                    method: "DELETE",
                })
            },
            invalidatesTags: ['versionList']
        }),
        changeToMainVersion: builder.mutation({
            query: ({ projectId }) => {
                return ({
                    url: `/project/${projectId}/changetomainversion`,
                    method: "PUT"
                })
            },
            invalidatesTags: ['versionList']
        })
    })
})

export const {
    useGetProjectDetailsQuery, useGetTocDataQuery, usePostTocDataMutation,
    useGetCriteriaDataQuery, usePostCriteriaDataMutation,
    useGetGrowthUniverseDataByIdQuery, useDeleteGouDataMutation,
    useGetSegmentationTypeQuery, usePostSegmentationTypeMutation, usePostCompleteSegmentationMutation,
    useDeleteSegmentationTypeMutation, useEditSegmentationTypeMutation,
    useGetBPRDataQuery, useDeleteBPRDataMutation, usePostBPRDataMutation,
    useGetGroupByUserListQuery, useGetKeyCompetitorsQuery, usePostKeyCompetitorMutation,
    usePostUpdateProjectStatusMutation, useLazyGetContributorsListQuery, useGetContributorsListQuery,
    usePostContributorsDataMutation, useGetGrowthMetricDatabyProjectIdQuery,useReCallMetricMutation,
    usePostGrowthMetricsDataMutation, useGetDrDataQuery, useUpdateTocIsSavedMutation,
    useCreateCloneTocMutation, useDeleteCloneMutation, useUpdateIsApplicableMutation,
    useGetEditorDataQuery, useGetProjectVersionListQuery, useDeleteProjectVersionMutation,
    useChangeToMainVersionMutation, useCreateProjectVersionMutation
} = ProjectPageAPISlice