const GrowthMetricsStaticData={
    "data": [
      {
        "id":"0",
        "field": "Growth Metrics 1",
        "units": "",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 0,
        "sort": 10
      },
      {
        "id":"1",
        "field": "Growth Metrics 2",
        "units": "M",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 1,
        "sort": 20
      },
      {
        "id":"2",
        "field": "Growth Metrics 3",
        "units": "M",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 0,
        "sort": 30
      },
      {
        "id":"3",
        "field": "Growth Metrics 4",
        "units": "",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 1,
        "sort": 40
      },
      {
        "id":"4",
        "field": "Growth Metrics 5",
        "units": "B",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 1,
        "sort": 50
      },
      {
        "id":"5",
        "field": "Growth Metrics 6",
        "units": "%",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 0,
        "sort": 60
      },
      {
        "id":"6",
        "field": "Growth Metrics 7",
        "units": "%",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 0,
        "sort": 70
      },
      {
        "id":"7",
        "field": "Growth Metrics 8",
        "units": "",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 0,
        "sort": 80
      },
      {
        "id":"8",
        "field": "Growth Metrics 9",
        "units": "",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 0,
        "sort": 90
      },
      {
        "id":"9",
        "field": "Growth Metrics 10",
        "units": "%",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 0,
        "sort": 100
      }
    ]
}
export {GrowthMetricsStaticData}