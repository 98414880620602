import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getUserDetailsFromLocalStorage } from '../Utils/userUtils'
import { useParams } from 'react-router-dom'
import DialogueBox from '../ComponentUtils/DialogBox'
import smallClose from '../images/png/close.png';
import { red } from '@mui/material/colors'
function AddContributors({ contributorsList, setContributorsList, project_Id }) {
    const [types, setTypes] = useState([])
    const [userGroup, setUserGroup] = useState([])
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedType, setSelectedType] = useState('')
    let initialData = {
        'created_by': getUserDetailsFromLocalStorage()?.user_id,
        'role_type': '',
        'user_id': ''
    }
    const [contributors, setContributors] = useState(initialData)
    const [contributorsData, setContributorsData] = useState([]);
    const [error, setError] = useState(false)
    // let project_Id = window.location.href?.split('/').pop()

    const headers = {
        Authorization: `Bearer ${document?.cookie?.split(';')?.find(token => token.startsWith(' $access_token'))?.split('=')[1]}`,
    }
    const getTypes = async () => {
        try {
            let response = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/groups`)
            setTypes(response?.data?.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getContributorsList = async (e) => {
        setError(false)
        setSelectedType(types.find((item) => item.slug === e.target.value));
        setSelectedUser('')
        let params = {
            "slug_name": e.target.value
        }
        try {
            let response = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/groupsbyuser`, { params })
            setUserGroup(response?.data?.data)
            setContributors(prevState => ({
                ...prevState,
                role_type: e.target.options[e.target.selectedIndex].text
            }));
        } catch (error) {
            console.log(error)
        }
    }

    const contributorOnChange = (e) => {
        setError(false)

        setSelectedUser(userGroup.find((item) => item.user_id === e.target.value))
        setContributors(prevState => ({
            ...prevState,
            user_id: e.target.value
        }));
    }
    const { projectId } = useParams();
    const saveContrib = async () => {
        if (!selectedUser?.user_id || !selectedType.role_type) {
            setError(true)
        } else {
            try {
                let response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_Id || projectId}/addcontributor`, {
                    created_by: contributors.created_by,
                    role_type: selectedType.role_type,
                    user_id: selectedUser?.user_id
                })
                if (response?.data?.message.startsWith('Contributor')) {
                    toast.success(response?.data?.message)
                    setContributorsList([])
                    listContributors()
                    setContributors(initialData)
                } else {
                    toast.error('Something went wrong')
                }
                setSelectedType('')
                setSelectedUser('')
            } catch (error) {
                console.log(error)
            }
        }
    }



    const mapRoleBased = (data) => {
        const roleMap = {};
        data.forEach(item => {
            const role = item.role_type;
            if (!roleMap[role]) {
                roleMap[role] = [];
            }
            roleMap[role].push(item);
        });
        return Object.keys(roleMap).map(role => ({
            role: role,
            data: roleMap[role]
        }));
    }


    const listContributors = async () => {
        try {
            let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_Id || projectId}/listcontributor`)
            let arr1 = data?.data?.data
            let arr2 = data?.data?.userdetails
            let userMap = {};
            if(data?.data?.message == "No records!"){
                setContributorsData([])
                // setContributorsList([])
                return 0;
            }
            arr2.forEach(user => {
                userMap[user.id] = user;
            });
            arr1.forEach(obj => {
                let user = userMap[obj.user_id];
                if (user) {
                    obj.first_name = user.first_name;
                    obj.last_name = user.last_name;
                }
            });
            if (contributorsList.length > arr1.length || contributorsList.length < arr1.length) {
                setContributorsList(arr1)
                setContributorsData(mapRoleBased(arr1));
                console.log(mapRoleBased(arr1), "list");
            }

        } catch (error) {
            console.log(error)
        }
    }

    const deleteContributors = async (e) => {
        let data = {
            'user_id': e
        }
        try {
            let response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_Id}/deletecontributor`, data)
            if (response?.data?.message == 'Contributor Deleted!') {
                listContributors()
                toast.success(response?.data?.message)
            } else {
                toast.error('Something went wrong')
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getTypes()
    }, [])
    useEffect(() => {
        listContributors()
        console.log(contributorsList, "list");

    }, [contributorsList])
    const [openDialog, setOpenDialog] = useState(false);
    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <>

            {/* <div  className="">
                <div  className="row gx-1">
                    <div  className="col-4">
                        <select  className="form-select form-select-sm" onChange={getContributorsList}>
                            <option disabled>Please select</option>
                            {
                                types?.map((ele, i) => <option key={i} value={ele?.slug}>{ele?.name}</option>)
                            }
                        </select>
                    </div>
                    <div  className="col-8">
                        <div  className="input-group input-group-sm mb-1">
                            <select  className="form-select form-select-sm" onChange={contributorOnChange}>
                                <option disabled>Please select</option>
                                {
                                    userGroup?.map((ele,i) => <option key={i} value={ele?.user_id}>{ele?.first_name + ' ' + ele?.last_name}</option>)
                                }
                            </select>
                            <span  className="input-group-text cursor-pointer" style={{ cursor: 'pointer',backgroundColor:'#0E4C8B',color:'white' }} onClick={AddContrib}>Add</span>
                        </div>
                    </div>
                </div>
                <div  className='input-group input-group-sm mt-3 '>
                    <h6>Contributors</h6>
                    {contributorsList?.map((e, index) => <div key={index}  className='d-flex w-100'>
                        <span
                            type="text"
                             className="form-control form-control-sm border-end-0 rounded-0"
                            name='name'
                        >{e?.first_name + ' ' + e?.last_name + ' - ' + e?.role_type}</span>
                        <button title='Remove Contributor' className="input-group-text rounded-0" onClick={()=>setOpenDialog(true) }>
                            <img
                                 className=""
                                src={require('../images/action_icon/close.png')}
                            />
                        </button>
                        <DialogueBox
                            dialogContentText={'This contributor for this project will be deleted. Continue?'}
                            dialogTitle={'Delete contributors'}
                            handleClose={handleClose}
                            handleConfirmation={()=>deleteContributors(e.user_id)}
                            openDialog={openDialog}
                            setOpenDialog={setOpenDialog}

                        />
                    </div>)}
                </div>
            </div>
            <div  className="" id="">
                <div  className="">
                    <div  className="">
                        <div  className="modal-body">
                        </div>
                    </div>
                </div>
            </div>

            <div  className="modal fade" id="myModal2">
                <div  className="modal-dialog">
                    <div  className="modal-content">
                        <div  className="modal-header border-bottom-0">
                            <h5  className="modal-title">Add Customers</h5>
                            <button type="button"  className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div  className="modal-body">
                            <div  className="row gx-1">
                                <div  className="col-4">
                                    <select  className="form-select form-select-sm">
                                        <option>Level</option>
                                        <option>Level 1</option>
                                        <option>Level 2</option>
                                        <option>Level 3</option>
                                    </select>
                                </div>
                                <div  className="col-8">
                                    <div  className="input-group input-group-sm mb-1">
                                        <input type="text"  className="form-control form-control-sm" placeholder="Search By Name" />
                                        <span  className="input-group-text">Add</span>
                                    </div>
                                </div>
                                <div  className="font12 mt-2">
                                    Customer will see radar-specific information WITHOUT the text descriptions, a list of companies names ONLY (with no other information), and a radar graphic with all dots but NO labels.
                                </div>
                            </div>
                        </div>
                        <div  className="modal-footer border-top-0">
                            <button type="button"  className="btn btn-danger btn-sm" data-bs-dismiss="modal">Cancel</button>
                            <button type="button"  className="btn btn-primary btn-sm" data-bs-dismiss="modal">Done</button>
                        </div>
                    </div>
                </div>
            </div> */}
            <div class="overflow-y-auto flex-grow-1 gp-contributor-modal-inner-content">
                <div class="w-100 d-flex align-items-stretch gp-contributor-input-wrapper">
                    <div class="gp-contributor-input">
                        {/* <select >
                            <option value="projectManager">Project Manager</option>
                        </select> */}
                        <select class="w-100" value={selectedType?.slug || ''} onChange={getContributorsList}>
                            <option value="" selected>Please select</option>
                            {
                                types?.map((ele, i) => <option key={i} value={ele?.slug}>{ele?.name}</option>)
                            }
                        </select>
                    </div>
                    <div class="gp-contributor-input">
                        {/* <select class="w-100">
                            <option value="pmbbr">PMBBR</option>
                        </select> */}
                        <select class="w-100" value={selectedUser?.user_id || ''} onChange={contributorOnChange}>
                            <option value="" selected>Please select</option>
                            {
                                userGroup?.map((ele, i) => <option key={i} value={ele?.user_id}>{ele?.first_name + ' ' + ele?.last_name}</option>)
                            }
                        </select>
                    </div>
                    <div class="gp-contributor-submit">
                        <button type="button" class="w-100" onClick={() => { saveContrib() }}>
                            Add
                        </button>
                    </div>

                </div>
                {error && <span className='pt-2' style={{color:'red'}}>Both fields are required</span>}
                <div class="gp-contributor-list-wrapper">
                    <p class="gp-contributor-list-heading">Contributors <span>*</span></p>
                    {
                        contributorsData?.length != 0 ? contributorsData?.map((ele) =>
                        (
                            <div class="w-100 d-flex align-items-center gp-contributors-row">
                                <h3>{ele?.role}</h3>
                                <ul class="d-flex align-items-center">
                                    {
                                        ele?.data?.map((val) => (
                                            <li
                                                class="d-flex align-items-center gp-contributor-chip gp-contributor-chip-active">
                                                <span class="text-nowrap">{`${val?.first_name} ${val?.last_name}`}</span>
                                                <div class="gp-contributor-cancel-btn" onClick={() => { deleteContributors(val?.user_id) }}>
                                                    <img src={smallClose} alt="close" />
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )) : null
                    }


                </div>
            </div>
        </>
    )
}

export default AddContributors
