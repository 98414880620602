import axios from 'axios';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { toast } from 'react-toastify';
import { useDeleteCloneMutation, usePostTocDataMutation, useUpdateIsApplicableMutation, useUpdateTocIsSavedMutation, useReCallMetricMutation } from '../../redux/slices/ProjectPageAPISlice';
import SSOLoader from '../SSOLoader';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { ProgressContext } from '../../Providers/ProgressProvider';
import { useParams } from 'react-router-dom';
import Footer from './Footer';
import DialogueBox from '../../ComponentUtils/DialogBox';
import wrong from '../../images/no.png';

function CompetitiveEnvironment(props) {
    const { isProjectEditable } = useContext(ProjectStatusContext);
    const [rows, setRows] = useState([
        {
            field_value: "",
            field_details: "",
            sort_by: 1,
        },
    ]);
    const [details, setDetails] = useState([]);
    const { projectId } = useParams();
    const [postTocData] = usePostTocDataMutation();
    const [updateTocIsSaved] = useUpdateTocIsSavedMutation();
    const [ReCallMetric] = useReCallMetricMutation();
    const { handleSectionSave } = useContext(ProgressContext);
    const [headerIsEdit, setHeaderIsEdit] = useState(false);
    const [header, setHeader] = useState("");
    const [loader, setLoader] = useState(false);
    const [Error, setError] = useState(false);
    const newFieldRef = useRef(null); // Ref to track the new input field

    const handleSaveToc = async () => {
        try {
            setLoader(true);
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    userid: getUserDetailsFromLocalStorage()?.user_id,
                    description: props?.description,
                    name: header,
                    isSaved: 1,
                },
            });
            setHeaderIsEdit(false);
            setLoader(false);
            toast.success('Saved Successfully');
        } catch (error) {
            console.log("There was an error!", error);
            setLoader(false);
            toast.error("Something went wrong");
        }
    };

    const headers = {
        Authorization: `Bearer ${document?.cookie
            ?.split(';')
            ?.find((token) => token.startsWith(' $access_token'))
            ?.split('=')[1]}`,
    };

    const addLine = () => {
        const newRow = {
            field_value: "",
            field_details: "",
            sort_by: rows.length + 1,
        };
        setRows((prevRows) => {
            const updatedRows = [...prevRows, newRow];
            return updatedRows;
        });

        // Focus on the new input field after a delay
        setTimeout(() => {
            if (newFieldRef.current) {
                newFieldRef.current.focus();
            }
        }, 0);
    };

    function validateFields(array) {
        return array.every(
            (item) => item.field_value.trim() !== "" && item.field_details.trim() !== ""
        );
    }

    const handleSave = async () => {
        if (validateFields(rows)) {
            setDetails(rows);
            const postData = {
                user_id: getUserDetailsFromLocalStorage().user_id,
                comp_env: rows,
                tocid: props.id,
            };
            try {
                let response = await axios.post(
                    `${process.env.REACT_APP_GO_IN_BASE_URL}/project/compenvironment/${projectId}`,
                    postData,
                    { headers, params: { projectid: projectId } }
                );
                getCompetitiveEnvironment();
                let saved = rows.length > 0 ? 1 : 0;
                updateTocIsSaved({ projectId: projectId, tocId: props.id, isSaved: saved });
                ReCallMetric();
                handleSectionSave(props.id);
                toast.success('Competitive Environment saved successfully');
            } catch (error) {
                console.log(error);
                toast.error('Something went wrong!');
            }
        } else {
            setError(true);
        }
    };

    const getCompetitiveEnvironment = async () => {
        try {
            var res = await axios.get(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/project/compenvironment/${projectId}/${props.id}`
            );
            if (Object.keys(res?.data?.data).length != 0) {
                const transformedData = transformData(res?.data?.data);
                setRows([...transformedData]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const transformData = (data) => {
        return data
            .map((item) => ({
                field_value: item.field_value,
                field_details: item.field_details,
                sort_by: item.sort_by,
            }))
            .sort((a, b) => a.sort_by - b.sort_by);
    };

    useEffect(() => {
        if (props?.go_in_id) {
            setHeader(props?.name);
            getCompetitiveEnvironment();
        }
    }, [props]);

    return (
        <>
            {loader ? <SSOLoader /> : null}
            <div style={{ paddingLeft: '92%' }}>
                <p
                    className="d-flex justify-content-end"
                    style={{
                        position: 'fixed',
                        color: 'red',
                        backgroundColor: 'yellow',
                    }}
                >
                    Auto&nbsp;save{' '}
                    <img
                        className="ms-2 mt-2"
                        style={{ height: '15px' }}
                        src={wrong}
                    />
                </p>
            </div>
            <div className="h-100 d-flex flex-column gp-editor-wrapper w-100">
                <div className="w-100 h-100 flex-grow-1 position-relative gp-editor">
                    <div className="w-100 h-100 gp-competitorSection gp-editing-content">
                        <div className="h-auto gp-competitor-header">
                            <p>{rows?.length} lines found</p>
                            {isProjectEditable && (
                                <button type="button" onClick={addLine}>
                                    <span className="mb-0">+ Add New</span>
                                </button>
                            )}
                        </div>
                        <div className="gp-competitor-body my-4">
                            {rows.map((row, index) => (
                                <div className="gp-competitor-row" key={index}>
                                    <textarea
                                        draggable="false"
                                        disabled={!isProjectEditable}
                                        rows={2}
                                        value={row.field_value}
                                        ref={index === rows.length - 1 ? newFieldRef : null} // Attach ref only to the last field
                                        onChange={(e) => {
                                            const updatedRows = [...rows];
                                            updatedRows[index].field_value =
                                                e.target.value;
                                            setRows(updatedRows);
                                            setError(false);
                                        }}
                                    ></textarea>
                                    <textarea
                                        draggable="false"
                                        rows={2}
                                        disabled={!isProjectEditable}
                                        value={row.field_details}
                                        onChange={(e) => {
                                            const updatedRows = [...rows];
                                            updatedRows[index].field_details =
                                                e.target.value;
                                            setRows(updatedRows);
                                            setError(false);
                                        }}
                                    ></textarea>
                                </div>
                            ))}
                        </div>
                        {Error && <span style={{ color: 'red' }}>Please fill all the fields</span>}
                        {isProjectEditable && (
                            <div className="d-flex justify-content-end">
                                <div className="gp-action-btn-part mt-1">
                                    <button type="button">Reset</button>
                                    <button
                                        type="button"
                                        className="gp-btn-fill"
                                        onClick={handleSave}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default CompetitiveEnvironment;
