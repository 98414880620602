// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.gp-border-btn {
    width: 18.7rem;
    height: 4.5rem;
    border-radius: 0.3rem;
    border: 1px solid var(--gp-btn-blue);
    background-color: white;
    outline: none;
    font-family: NunitoSans;
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--gp-btn-blue);
}
.gp-border-btn:hover{
    background-color: white; 
    color: var(--gp-btn-blue);
    border: 1px solid var(--gp-btn-blue); 
}`, "",{"version":3,"sources":["webpack://./src/layout/CSS/OtherModel.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,cAAc;IACd,qBAAqB;IACrB,oCAAoC;IACpC,uBAAuB;IACvB,aAAa;IACb,uBAAuB;IACvB,iBAAiB;IACjB,gBAAgB;IAChB,yBAAyB;AAC7B;AACA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,oCAAoC;AACxC","sourcesContent":["button.gp-border-btn {\n    width: 18.7rem;\n    height: 4.5rem;\n    border-radius: 0.3rem;\n    border: 1px solid var(--gp-btn-blue);\n    background-color: white;\n    outline: none;\n    font-family: NunitoSans;\n    font-size: 1.8rem;\n    font-weight: 500;\n    color: var(--gp-btn-blue);\n}\n.gp-border-btn:hover{\n    background-color: white; \n    color: var(--gp-btn-blue);\n    border: 1px solid var(--gp-btn-blue); \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
