import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { useGetKeyCompetitorsQuery, usePostKeyCompetitorMutation, useUpdateTocIsSavedMutation, useReCallMetricMutation } from '../../redux/slices/ProjectPageAPISlice';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import { toast } from 'react-toastify';
import { ProgressContext } from '../../Providers/ProgressProvider';
import deleteImg from '../../assets/images/delete.svg'
import DialogueBox from '../../ComponentUtils/DialogBox';
import axios from 'axios';
const CompetitorsBySection = ({ competitorObj, projectData, tocId }) => {
  const { handleSectionSave } = useContext(ProgressContext)
  const { isProjectEditable } = useContext(ProjectStatusContext)
  const [ReCallMetric] = useReCallMetricMutation();
  const [inputValue, setInputValue] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const { projectId } = useParams();
  const { data: keyCompetitorData, isLoading, isSuccess, isError, error, refetch } = useGetKeyCompetitorsQuery({ projectId: projectId, nodeId: competitorObj.id, tocId: tocId })

  useEffect(() => {
    if (isSuccess) {
      console.log(keyCompetitorData?.data?.map(ele => ele?.desc))

      setTableData(keyCompetitorData?.data)
    }
  }, [keyCompetitorData, isLoading, isSuccess, isError])

  const handleSaveKeyCompetitor = async () => {
    //  console.log(inputValue);
    const items = inputValue.split('\n').map((item) => item.trim()).filter(Boolean);
    const sendData = items?.map(item => item.split('\t')[0]).flat();
    try {
      postKeyCompetitor({
        projectId: projectId,
        body: {
          userid: getUserDetailsFromLocalStorage().user_id,
          seg_id: competitorObj.id,
          competitors: sendData,
          tocid: tocId,
        }
      }).then((res) => {
        toast.success("Saved Successfully");
        handleSectionSave(tocId)
        let saved = sendData.length > 0 || tableData.length > 0 ? 1 : 0
        updateTocIsSaved({ projectId: projectId, tocId: tocId, isSaved: saved })
        ReCallMetric()
        setInputValue('')
        refetch()
      })
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong")
    }
  };


  const [postKeyCompetitor] = usePostKeyCompetitorMutation();
  const [updateTocIsSaved] = useUpdateTocIsSavedMutation()
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    const inputValue1 = e.target.value;
    // const items = inputValue1.split('\n').map((item) => item.trim()).filter(Boolean);
    // setTableData(items?.map(item => item.split('\t')[0]));
  };



  const handlePaste = (e) => {
    // const pastedData = e.clipboardData.getData('text/plain');
    // const rows = pastedData.split('\n');
    // setTableData(rows?.map(item => item.split('\t')[0]));
  };

  const handleConfirmation = async () => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/deletekeycompetitors/${projectData?.go_in_id}/${deleteId}`, {
        data: {
          userid: getUserDetailsFromLocalStorage()?.user_id,
        }
      });
      toast.success("Deleted Successfully")
      // console.log('Response:', response.data);
      let saved = tableData.length > 1 ? 1 : 0
      updateTocIsSaved({ projectId: projectId, tocId: tocId, isSaved: saved })
      ReCallMetric()
      setInputValue('')
      refetch()
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong")
    }

  }

  return (
    <>
      <div class="w-100 h-100 gp-key-competitorSection gp-editing-content">
        <div class="gp-key-competitor-body">
          <div class="gp-add-segment">
            <div class="header">
              <h3>{competitorObj.name}</h3>
              <button type="button">
                {/* <img src="../../assets/images/instruction.svg"
                                        alt="instruction"> */}
              </button>
            </div>
            <div class="gp-input-area">
              <textarea
                disabled={!isProjectEditable}
                onPaste={handlePaste}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Paste from Excel or Enter items separated by Enter"
              />
            </div>
          </div>
          <div class="gp-competitors-table-wrapper">
            {tableData.length > 0 && (
              <table class="gp-competitors-table">
                <thead>
                  <tr>
                    <th colspan="2">Competitors</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td key={`${rowIndex}`}>{row?.desc}</td>
                      <td>
                        <div class="d-flex justify-content-end">
                          <button type="button">
                            {/* <img src="../../assets/images/edit.svg"
                                                        alt="edit"> */}
                          </button>

                          {isProjectEditable &&
                            <button type="button" onClick={() => { setDeleteId(row?.id); setOpenDialog(true) }}>
                              <img src={deleteImg}
                                alt="delete" />
                            </button>
                          }

                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>

        {isProjectEditable &&
          <div className='d-flex justify-content-end'>
            <div class="gp-action-btn-part mt-2 me-4">

              <button onClick={handleSaveKeyCompetitor} className="gp-btn-fill" disabled={!isProjectEditable} type="button" >Save</button>
            </div>
          </div>
        }
      </div>
      <DialogueBox
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        dialogTitle="Confirm Deletion"
        dialogContentText="Are you sure you want to delete this Competitor?"
        handleConfirmation={handleConfirmation}
        handleClose={() => setOpenDialog(false)}
      />
    </>
  )
}

export default CompetitorsBySection



