import React, { createContext, useEffect, useMemo, useState } from 'react';
import { getUserDetailsFromLocalStorage } from '../Utils/userUtils';
import { useParams } from 'react-router-dom';
import { useGetProjectDetailsQuery } from '../redux/slices/ProjectPageAPISlice';

export const ProjectStatusContext = createContext();

export const ProjectStatusProvider = ({
    children,
    activeProjectStage,
    returnStatus,role_type,
    isDisabled
})=>{
    const stagesData=useMemo(()=>{
        return [
            {
                statusName:'Analyst',
                value: 'draft',
                hasNextStageAccess:true,
                nextStage: 'toPm',
                canSendBack:false,
                sendBackTo:null,
                sendBackKeyword:null,
                forwardStatusText:'Send to Project Manager',
                sendBackText:null,
                roles:['analyst']
            },
            {
                statusName:'Project Manager',
                value: 'toPm',
                hasNextStageAccess:true,
                nextStage: 'toEditor',
                canSendBack:true,
                sendBackTo:'draft',
                sendBackKeyword:'toAnalyst',
                forwardStatusText:'Send to Quality Control',
                sendBackText:'To Analyst',
                roles:['pm']
            },
            {
                statusName:'Quality Control',
                value: 'toEditor',
                hasNextStageAccess:true,
                nextStage: 'toQc',
                canSendBack:true,
                sendBackTo:'draft',
                sendBackKeyword:'toAnalyst',
                forwardStatusText:'Send to Electronic Publishing',
                sendBackText:'To Analyst',
                roles:['subeditor','editor']
            },
            {
                statusName:'Electronic Publishing',
                value: 'toQc',
                hasNextStageAccess:true,
                nextStage: 'published',
                canSendBack:true,
                sendBackTo:'draft',
                sendBackKeyword:'toAnalyst',
                forwardStatusText:'Send to Publishing',
                sendBackText:'To Analyst',
                roles:['qc']
            },
            {   
                statusName:'Post',
                value:'published',
                hasNextStageAccess:false,
                nextStage: null,
                canSendBack:true,
                sendBackTo:'draft',
                sendBackKeyword:'toAnalyst',
                forwardStatusText:null,
                sendBackText:'To Analyst',
                roles:[]
            }
        ]
    },[])
    const projectStages = useMemo(()=>{
        return [
            {
                statusName:'Analyst',
                value: 'draft',
                hasNextStageAccess:true,
                nextStage: returnStatus?stagesData[stagesData.findIndex((item)=>item.value===returnStatus)].nextStage:'toPm',
                canSendBack:false,
                sendBackTo:null,
                sendBackKeyword:null,
                forwardStatusText:returnStatus?stagesData[stagesData.findIndex((item)=>item.value===returnStatus)].forwardStatusText:'Send to Project Manager',
                sendBackText:null,
                roles:['analyst']
            },
            ...stagesData.slice(1)
        ]
    },[returnStatus])
    const [validations, setValidations] = useState({
        errors: {},
        warnings: {},
        fileUploaded: false
      });
      const [selectedTab, setSelectedTab] = useState(0)
      const addValidation = (type, key, message) => {
        setValidations(prev => ({
          ...prev,
          [type]: { ...prev[type], [key]: message }
        }));
      };
    
      const removeValidation = (type, key) => {
        setValidations(prev => {
          const newValidations = { ...prev[type] };
          delete newValidations[key];
          return { ...prev, [type]: newValidations };
        });
      };
    
      const setFileUploaded = (status) => {
        setValidations(prev => ({ ...prev, fileUploaded: status }));
      };
    
    const [isActiveStage,setIsActiveStage]=useState(activeProjectStage)
    
    useEffect(()=>{
        if(activeProjectStage==='toAnalyst'){
            setIsActiveStage('draft')
        }
        else setIsActiveStage(activeProjectStage)
    },[activeProjectStage])
    const [stageInfo,setStageInfo]=useState(stagesData.find((item)=>item.value===isActiveStage))
    const [roleType,setRoleType]=useState(role_type)

    useEffect(()=>{
        setRoleType(role_type)
    },[role_type])
    const {projectId}=useParams();
    const {data,isLoading,isSuccess}=useGetProjectDetailsQuery({projectId:projectId})
    useEffect(()=>{
        const contributorItem=data?.data.contributor_list?.find((item)=>item.created_by===getUserDetailsFromLocalStorage().user_id)
        if(roleType==='pm' && 
            contributorItem?.created_by===getUserDetailsFromLocalStorage().user_id){
                setStageInfo((prev)=>{
                    return{
                        ...prev,
                        hasNextStageAccess:true,
                        nextStage: 'toEditor',
                        roles:['pm'],
                        forwardStatusText:'Send to Quality Control',
                    }
        
                })
            }
        else setStageInfo(stagesData.find((item)=>item.value===isActiveStage))
        
        
    },[isActiveStage,stagesData,isSuccess,data])
    // const [isProjectEditable, setIsProjectEditable] = useState(false);


    

    const isProjectEditable=useMemo(()=>{
        const contributorItem=data?.data.contributor_list?.find((item)=>item.created_by===getUserDetailsFromLocalStorage().user_id)
        const contributorUser=data?.data.contributor_list?.find((item)=>item.user_id===getUserDetailsFromLocalStorage().user_id)
        
        if(roleType==='pm' && (contributorItem?.created_by===getUserDetailsFromLocalStorage().user_id || contributorUser.user_id===getUserDetailsFromLocalStorage().user_id)){
                return true;
        }
        
        else return (stageInfo?.roles.includes(roleType)&&isDisabled===0)
      },[stageInfo,roleType,isDisabled,isActiveStage,projectStages,data])

    const projectStatusContextValue = useMemo(
        ()=>{
            return {
                projectStages,
                isActiveStage,
                stageInfo,
                isProjectEditable,
                validations, 
                addValidation, 
                removeValidation, 
                setFileUploaded,
                roleType,
                isDisabled,
                selectedTab, 
                setSelectedTab
            }
        },[isActiveStage,projectStages,
            stageInfo,returnStatus,validations,
            selectedTab, setSelectedTab,isDisabled
        ])
    return (
        <ProjectStatusContext.Provider value={projectStatusContextValue}>
          {children}
        </ProjectStatusContext.Provider>
      );
}